import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  gridTopImage: {
    [theme.breakpoints.up('lg')]: {
      height: "auto",
      textAlign: "center",
    },
    [theme.breakpoints.down('md')]: {
      height: "auto",
      textAlign: "center",
      // marginBottom:"15px",
    },
  },
  boxContainer: {    
    backgroundColor: "#fff",
    padding: "0px 0 70px",
    borderRadius: "10px",
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: 'white !important' ,
      color : "#000000 !important"
    },
    '@media (prefers-color-scheme: light)': {
      backgroundColor: 'white !important' ,
      color : "#000000 !important"
    },
    '@media (prefers-color-scheme: no-preference)': {
      backgroundColor: 'white !important' ,
      color : "#000000 !important"
    },
  },
  gridMiddleImage: {
    padding: "0 20px !important",
    marginBottom: "10px",
  },
  typography: {
    textAlign: "center",
    padding: "0 20px !important",
  },
  gridButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-50px",
    marginBottom: "10px"
  },
  goButton: {
    border: "5px solid #f7b6ac !important",
    padding: "20px 25px !important",
    fontWeight: "bold",
    fontSize: "1.2rem !important",
    borderRadius: "8px !important",
    backgroundColor : "#fe4d4e !important",
  },
  gridLastImage: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "25px"
  },
  typographyLastImage: {
    color: "#ffffff",
    fontWeight: "bold !important"
  },
  menuBottom: {
    display: "flex",
    backgroundColor: "lightgray",
    padding: "18px",
    justifyContent: "space-around",
    width: "100%",
    bottom: "0"
  },
  menuTypoBottom: {
    
    fontSize: "0.9rem !important",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline",
   },
  }
}));
