import React, { useState } from 'react';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

// function DatePickerInput() {  
const DatePickerInput = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <MobileDatePicker
        // label="Sélectionnez une date"
        defaultValue={'2022-04-17'}
        // value={selectedDate}
        // onChange={handleDateChange}
        // renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
}

export default DatePickerInput;
