// Config starter code
import { createChatBotMessage , createCustomMessage} from "react-chatbot-kit";
import { getCoordonnees, setMessage, getCoordonneesByOrdre, setPhotoName, dateNow, validatePhone, getChampsEvent } from '../Utilities';
import Image from "../../../assets/img";
import DogPicture from "../Template/DogPicture.jsx";
import GifCool from "../Template/GifCool";
import Tenter from "../Template/Tenter";
import ResponseCheckbox from "../Template/ResponseCheckbox";
import Options from "../Template/Options";
import OptionsOptin from "../Template/OptionsOptin";
import CustomMessage from "./CustomMessage";
import OptionsNewLetter from "../Template/OptionNewsLetter";
import DateInput from "../Template/DateInput";

const botName = "Virtual booth";

//console.log(getChampsEvent());
//console.log(validatePhone('0033630672013'));

// const CustomMessage = ({ message }) => {
//   return (
//     // <div
//     //   className="react-chatbot-kit-chat-bot-message"
//     //   dangerouslySetInnerHTML={{ __html: message }}
//     // />
//     message
//   );
// };

const CustomBotAvatar = (props) => {
  // console.log("bot props", props);
  return (
    <div className="react-chatbot-kit-chat-avatar-container">
      <img
        src={Image.logoBotImg}
        className="react-chatbot-kit-chat-avatar-image"
      />
    </div>
  );
};

const OptionsExpemle = () => {
  const options = [
    { text: "Homme" , id: 'homme' },
    { text: "Femme", id: 'femme' },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      id={`option-${option.id}`}
      //className="learning-option-button"
      className="btn btn-rounded btn-secondary btn-sm"
      key={option.id}
      // onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return  (<div className="learning-options-container">
      {optionsMarkup}
      </div>);
};




const config = {
  //initialMessages: [createChatBotMessage(`Hi! I'm ${botName}`)],
  initialMessages: [
    //createChatBotMessage(`Hello, ${'merci pour ta photo !'}`),
    createChatBotMessage('Super ✌️, merci pour ta photo ! 🤩', {
      withAvatar: true,
      widget : 'GifCool',
    }),
    // createCustomMessage('qsdfqsdfqs fqsfd', '', {
    //   loading : true,
    //   delay: 2000,
    //   withAvatar: true,
    // }),
    // createChatBotMessage(<span><b>Pour recevoir ta photo, </b> nous avons besoin de quelques infos !  🤗 </span>,{
    //   delay: 2000,
    //   withAvatar: true,
    //   widget : 'GifCool',
    // }),

    createChatBotMessage(setMessage(getCoordonneesByOrdre()),
    { 
      key : getCoordonneesByOrdre().type,
      champ_id : getCoordonneesByOrdre().id,
      ordre : getCoordonneesByOrdre().ordre,//1,
      is_last : getCoordonneesByOrdre().is_last,
      required : false,
      withAvatar: true,
      widget: getCoordonneesByOrdre().widget !== undefined ? getCoordonneesByOrdre().widget : false,
      delay: 2000,
      // type : 'custom',
      // message : 'text',
   })
  ],
  botName: botName,
  /*customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },*/
  /*customComponents: {
     // Replaces the default header
    header: () => <div style={{ backgroundColor: 'red', padding: "5px", borderRadius: "3px" }}>This is the header</div>,
  },*/
  customComponents: {
    // botChatMessage: (props) => <CustomMessage {...props} />,
    botAvatar: (props) => <CustomBotAvatar {...props} />,
  },
  // customMessages: {
  //   custom: (props) => <CustomMessage {...props} />,
  // },
  widgets: [
    {
      widgetName: "GifCool",
      widgetFunc: (props) => <GifCool {...props} />,
    },
    {
      widgetName: "dogPicture",
      widgetFunc: (props) => <DogPicture {...props} />,
    },
    {
      widgetName: "ResponseCheckbox",
      widgetFunc: (props) => <ResponseCheckbox {...props} />,
    },
    {
      widgetName: "Options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "OptionsOptin",
      widgetFunc: (props) => <OptionsOptin {...props} />,
    },
    {
      widgetName : 'Tenter',
      widgetFunc : (props) => <Tenter {...props} />,
    },
    {
      widgetName: "OptionsNewLetter",
      widgetFunc: (props) => <OptionsNewLetter {...props} />,
    },
    {
      widgetName: "DateInput",
      widgetFunc: (props) => <DateInput {...props} />,
    }
  ],
  runInitialMessagesWithHistory : true,
};

export default config;
