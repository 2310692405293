import { useState, useRef, useEffect } from "react";
import { Container, Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useStyle } from "./Photos.style";
import ReactGA from "react-ga4";
import Camera from "./Camera";
import {isMobile} from 'react-device-detect';


const presetResolutions = [
    {label:"ask 1920x1080", value:{width:1920,height:1080}},
    {label:"ask 1280x720", value:{width:1280,height:720}},
    {label:"ask 640x480", value:{width:640,height:480}},
    {label:"ask 1128x1176", value:{width:1128,height:1176}},
  ]

export default function CustomCaptures({ updateCapturedImage, updateLoader, cadreName }) {
    const idTimer = useRef(null);
    const [timer, setTimer] = useState(null);

    const classes = useStyle();
    const [allLoaded, setAllLoaded] = useState(false)
    const [noCamera, setNoCamera] = useState(false);

    let defaultResolution = {width:891,height:891};
    if (cadreName === 'cadre2.png') {
      defaultResolution = {width:1750 ,height:1030};
      if (isMobile) {
        defaultResolution = {width:1030 ,height:1780};
      }
    } else if (cadreName === 'cadre3.png') {
      defaultResolution = {width:1130 ,height:1450};
      if (isMobile) {
        defaultResolution = {width:1450 ,height:1130};
      }
    }

    const makeAllLoader = (bool) => {
      // document.getElementById('id_dbgMakeAllLoader').innerHTML = 'MakeAllLoader : ' + bool
      setAllLoaded(bool);
    };

    const setErrorNomera = (bool) => {
      // console.log('no cam : ' + bool)
      // document.getElementById('id_dbgNoCam').innerHTML = 'No cam : ' + bool
      setNoCamera(bool);
    };
  

    const [cameras, setCameras] = useState([]);
    const [selectedCameraLabel, setSelectedCameraLabel] = useState("");
    const [desiredCamera, setDesiredCamera] = useState("founder");
    const [desiredResolution, setDesiredResolution] = useState(defaultResolution);
    const [currentResolution, setCurrentResolution] = useState("");
    const [facingMode, setFacingMode] = useState('user');
    
    const resSel = useRef(null);
    const camSel = useRef(null);
    const webCamRef = useRef();

    useEffect(() => {
      // startup();
      // setSelect();
  
      if (timer === 0) {
        clearTimer();
        // takepicture();
        capturedImage()
      }
    });

    const onOpened = (cam,camLabel) => {
      console.log("opened");
      console.log(camLabel);
      // document.getElementById('id_dbgCamLabel').innerHTML = 'Cam label : ' + camLabel
      setCurrentResolution(cam.videoWidth+"x"+cam.videoHeight);
      setSelectedCameraLabel(camLabel);
    }
    
  
    const onClosed = () => {
      console.log("closed");
    }
  
    const onDeviceListLoaded = (devices) => {
      console.log(devices);
      setCameras(devices);
    }
  
    const onCameraSelected = (e) => {
      setDesiredCamera(e.target.value);
      setSelectedCameraLabel(e.target.value);
    }
  
    const onResolutionSelected = (e) => {
      const width = e.target.value.split("x")[0];
      const height = e.target.value.split("x")[1];
      setDesiredResolution({width:width,height:height})
    }

    const onCaptureImage = (e) => {
        startTimer();
    }

    const capturedImage = (e) => {
      let options = {
        widthSource : defaultResolution.width,
        heightSource : defaultResolution.height,
      }
      if (isMobile) {
        options = {
          widthSource : defaultResolution.height,
          heightSource : defaultResolution.width,
        }
      }

      const resultImage = webCamRef.current.getPicture(options);
      updateCapturedImage(resultImage, 1);
    }

    const upladImage =  async (e) => {

        ReactGA.event({
          category: 'Photo',
          action: 'Upload une photo'
        });
    
        const file = e.target.files[0];
        var extension = file.type
        const validExtesion = ['image/x-png','image/gif','image/jpeg', 'image/png', 'image/jpg'];
        if (validExtesion.includes(extension)) {
          const base64 = await convertToBase64(file);
          const originalImage = new Image();
          originalImage.src = base64;
          originalImage.onload = () => {
            const smartcrop = require('smartcrop');
            smartcrop.crop(originalImage, defaultResolution).then(function(result) {
              console.log(result.topCrop);
    
              var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d'),
                    crop = result.topCrop;
    
                    canvas.width = defaultResolution.width;
                    canvas.height = defaultResolution.height;
                    if (isMobile) {
                      canvas.width = defaultResolution.height;
                      canvas.height = defaultResolution.width;
                    }
    
                ctx.patternQuality = 'best';
                ctx.filter = 'best';
                ctx.drawImage(originalImage, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);
                var resultimg = canvas.toDataURL('image/jpeg');
                updateCapturedImage(resultimg, 2);
            });
          }
    
          updateLoader(true);
        }
      };
    
    const convertToBase64 = (file)  => {
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.onerror = error => reject(error);
      });
    };
  
    const changeMode = () => {
      if (facingMode === "environment") {
        setFacingMode('user');
      } else {
        setFacingMode('environment');
      }
    }

    const clearTimer = () => {
      clearInterval(idTimer.current);
    };

    const startTimer = () => {
      setTimer(4);
      idTimer.current = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    };

    const Loading = () => {
      return (
        <div className="txtChargement">
          Chargement...
        </div>
      )
    }

    const NoCameraError = () => {
      return (
        <div className="txtChargement error">
          Une erreur est survenue. Veuillez recharger la page et réessayer.
        </div>
      )
    }

    console.log('allLoaded : ' + allLoaded)
    console.log('noCamera : ' + noCamera)



    return (
        <Container className="App" maxWidth="sm" style={{ position: "relative", marginTop:"35px" }}>
          <Container className="App" maxWidth="sm" style={{ position: "relative", marginTop:"35px" }}>
            <Camera
                ref={webCamRef}
                isActive= {true}
                desiredCamera={desiredCamera}
                desiredResolution={desiredResolution}
                onOpened={onOpened}
                onClosed={onClosed}
                onDeviceListLoaded={onDeviceListLoaded}
                facingMode={facingMode}
                makeAllLoader={makeAllLoader}
                setErrorNomera={setErrorNomera}
            >
            </Camera>
            <div className={timer === 0 ? "hide counter" : "counter"}>
              {timer}
            </div>
          </Container>
          { allLoaded ? (
          <Container maxWidth="sm" className={classes.buttonPhotos} id="buttonPhotos">
            <Box className={classes.containerButtonPhotos}>
    
              <Grid className={classes.buttonMediumPhotos} style={{ width: "30%" }} id="blocUploadPhoto">
                <IconButton aria-label="insertPhoto">
                  <input type="file" className="photoUpload" onChange={(e) => upladImage(e)} accept="image/*" />
                  <InsertPhotoIcon style={{ fontSize: "40px", color: "#fe4d4e" }} />
                </IconButton>
              </Grid>

              <Grid className={classes.buttonMediumPhotos} style={{ width: "40%" }}>
                <Button
                  variant="contained"
                  onClick={onCaptureImage}
                  id="startbutton"
                  className={classes.takeButtonPhotos}
                  style={{ backgroundColor:"#fe4d4e", border: "3px solid #f7b6ac", }}
                >
                  <CameraAltOutlinedIcon
                    style={{ color: "#fff", fontSize: "45px"}}
                  />
                </Button>
              </Grid>
              
              <Grid
                className={`${classes.buttonMediumPhotos + " kl_switchCam"}`}
                style={{ width: "30%" }}
                onClick={(e) => {
                  e.preventDefault();
                  changeMode();
                }}
              >
                <IconButton aria-label="switchCamera">
                  <CameraswitchIcon
                    style={{ fontSize: "40px", color: "#fe4d4e" }}
                  />
                </IconButton>
              </Grid>
            </Box>

          </Container>) : noCamera ? (<NoCameraError />) : (<Loading />) } 
          {/* <div id="id_dbgMakeAllLoader"></div>          
          <div id="id_dbgCam">{ 'allLoaded : ' + allLoaded}</div>
          <div id="id_dbgCam2">{'noCamera : ' + noCamera}</div>
          <div id="id_dbgNoCam"></div>
          <div id="id_dbgCamLabel">{'Cam label : '}</div> */}
        </Container>
        
    );
}