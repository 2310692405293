import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Photos from "./components/Photos/Photos";
import Chatbot from "./components/Chatbot/Chatbot";
import Form from './components/Form/Form';
import SelectedPhotos from "./components/Photos/SelectedPhotos";
import Mention from "./components/Contenu/Mention";
import ConditionJeu from "./components/Contenu/Cgj";
import Selections from './components/Photos/Selections';
import ReactGA from "react-ga4";
import CustomCaptures from './components/Photos/CustomCaptures';
import NewCaptures from './components/Photos/NewCaptures';
import Cadres from './components/Photos/Cadres';
const TRACKING_ID = "G-2XFC6B6VKH";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");


function App() {

  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "window.location.pathname + window.location.search" });
  }, []);

  return (
    <>
    {/* <Container className="App" maxWidth="sm" style={{ position: "relative" }}> */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/debughome" element={<Home />} /> */}
        <Route path="/select" element={<Selections />} />
        <Route path="/cadres" element={<Cadres />} />
        <Route path="/photo" element={<Photos />} />
        {/* <Route path="/debugphoto" element={<Photos />} /> */}
        <Route path="/photo2" element={<Photos cadreName="cadre2.png" />} />
        <Route path="/photo3" element={<Photos cadreName="cadre3.png" />} />

        <Route path="/custom" element={<CustomCaptures />} />
        <Route path="/new-capture" element={<NewCaptures />} />
        <Route path="/selected-photo" element={<SelectedPhotos />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/form" element={<Form />} />
        <Route path="/mention-legale" element={<Mention />} />
        <Route path="/condition-jeu" element={<ConditionJeu isPopup={false} />} />
      </Routes>
    {/* </Container> */}
    </>
  );
}

export default App;
