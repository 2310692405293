
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography, Container } from "@mui/material";
import { useStyle } from "./Selections.style";
import Logotop from '../../assets/img/logo-everial.png';
import classnames from "classnames";
import Cadre from '../../assets/img/cadre/cadre.png';
import Cadre2 from '../../assets/img/cadre/cadre2.png';
import Cadre3 from '../../assets/img/cadre/cadre3.png';

export default function Cadres() {
    const navigate = useNavigate();
    const classes = useStyle();


    return (
        <>
          <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
                <Grid className={classes.gridTopImage}>
                <img src={Logotop} className="logoTopHome" alt='logo' />
                </Grid>
                <Box className={classes.boxContainerSelections}>
                <Grid>
                    <Typography className={classnames(classes.typography)}>
                        <b>Veuillez séléctionner un cadre.</b>
                    </Typography>
                    <Grid 
                    onClick={(e) => navigate("/photo2")}
                    className={classes.gridButtonSelections}>
                        <img src={Cadre2} className="cadrePhoto" alt='cadre' />
                    </Grid>
                    <Grid 
                    onClick={(e) => navigate("/photo3")}
                    className={classes.gridButtonSelections}>
                        <img src={Cadre3} className="cadrePhoto" alt='cadre' />
                    </Grid>
                    <Grid
                    onClick={(e) => navigate("/photo")}
                    className={classes.gridButtonSelections}>
                        <img src={Cadre} className="cadrePhoto" alt='cadre' />
                    </Grid>
                </Grid>
                </Box>          
          </Container>
        </>
        );
}
