import React from "react";

import "../styles/options.css";

const Options = (props) => {
  //console.log(props)
  const options = [
    /*{ text: "Javascript", handler: () => {}, id: 1 },
    { text: "Data visualization", handler: () => {}, id: 2 },
    { text: "APIs", handler: () => {}, id: 3 },
    { text: "Security", handler: () => {}, id: 4 },
    { text: "Interview prep", handler: () => {}, id: 5 },*/
    { text: "Homme", handler:  () => props.actionProvider.getGender('Homme', 'Mr'), id: 'homme' },
    { text: "Femme", handler:  () => props.actionProvider.getGender('Femme', 'Mme'), id: 'femme' },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      id={`option-${option.id}`}
      //className="learning-option-button"
      className="btn btn-rounded btn-secondary btn-sm"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default Options;