import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "@mui/material/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useStyle } from "./Selections.style";
//import Image from "../../assets/img";
import classnames from "classnames";
import ReactGA from "react-ga4";
import UploadIcon from '@mui/icons-material/Upload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Fusions from "./Fusions";
import { storeDataImage } from '../Chatbot/Utilities';
import Logotop from '../../assets/img/logo-everial.png';


export default function Selections() {
  const navigate = useNavigate();
  const [capturedImg, setCapturedImg] = useState(null);
  const [loader, setLoader] = useState(null);
  const classes = useStyle();
  const [sourcePhoto, setSourcePhoto] = useState(null)
  const [videoWidth, setVideoWidth] = useState(1150);
  const [videoHeigth, setVideoHeigth] = useState(1182);
  const updateCapturedImage = (img, sourcePhoto) => {
    setCapturedImg(img);
    storeDataImage(img);
    setSourcePhoto(sourcePhoto);
  };
  const goToPhoto = () => {
    ReactGA.event({
      category: 'Home',
      action: 'C\'est parti'
    });
    navigate("/photo");
  }
  const updateLoader = (bool) => {
    setLoader(bool);
  };
  const UploadControl = ({ children, value, onChange, disabled, accept }) => {
    return (
      <label htmlFor="contained-button-file" className="m-0 w-100">
        <input
          value={value}
          accept={accept}
          disabled={disabled}
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={disabled ? () => {} : onChange}
        />
        {children}
      </label>
    );
  };

//   const uploadImage = ({ target: { files } }) => {
    const upladImage =  async (e) => {
    // const loadedImage = files[0];
   // ---
   const file = e.target.files[0];

    // const file = files[0];
    // console.log(file);

    var extension = file.type
    const validExtesion = ['image/x-png','image/gif','image/jpeg', 'image/png', 'image/jpg'];
    if (validExtesion.includes(extension)) {
      const base64 = await convertToBase64(file);
      const originalImage = new Image();
      originalImage.src = base64;
      originalImage.onload = () => {
        const smartcrop = require('smartcrop');
        smartcrop.crop(originalImage, { width: videoWidth, height: videoHeigth }).then(function(result) {
          console.log(result.topCrop);

          var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                crop = result.topCrop;

                canvas.width = videoWidth;
                canvas.height = videoHeigth;

            ctx.patternQuality = 'best';
            ctx.filter = 'best';
            ctx.drawImage(originalImage, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);
            var resultimg = canvas.toDataURL('image/jpeg');
            updateCapturedImage(resultimg, 2);
        });
      }

      updateLoader(true);
    }
   //---
  };

    const convertToBase64 = (file)  => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = error => reject(error);
        });
    };

    return (
    <>
      <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
      {!capturedImg ? (
        <>
            <Grid className={classes.gridTopImage}>
            <img src={Logotop} className="logoTopHome" />
            </Grid>
            <Box className={classes.boxContainerSelections}>
            <Grid>
                <Typography className={classnames(classes.typography)}>
                    <b>Comment souhaites-tu envoyer une photo ?</b>
                </Typography>
                <Grid className={classes.gridButtonSelections}>
                    {/* <Button
                        onClick={() => goToPhoto()}
                        variant="contained"
                        size="large"
                        startIcon={<UploadIcon style={{ fontSize: "35px" }} />}
                        className={classes.choisirPhoto}
                    >
                        Choisir votre photo
                    </Button> */}

                    <Button 
                        variant="contained"
                        size="large"
                        startIcon={<UploadIcon style={{ fontSize: "35px" }} />}
                        className={classes.choisirPhoto}
                        >
                        <UploadControl onChange={(e) => upladImage(e)} accept="image/*">
                        Importer depuis mes fichiers
                        </UploadControl>
                    </Button>

                    <Button
                        onClick={() => goToPhoto()}
                        variant="contained"
                        size="large"
                        startIcon={<CameraAltIcon style={{ fontSize: "35px" }} />}
                        className={classes.photoWebcam}
                        style={{ marginTop: "35px" }}
                    >
                        Prendre une photo avec ma webcam
                    </Button>
                </Grid>
            </Grid>
            </Box>
        </>
      ) : (<Fusions
        imgCapture={capturedImg}
        updateCapturedImage={updateCapturedImage} updateLoader={updateLoader} sourcephoto={sourcePhoto}
      />)}
      </Container>
    </>
    );
}
