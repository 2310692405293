// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';

const ResponseCheckbox = () => {

  return (
    <div>
        <label className="custom-control custom-radio">
            <input id="radio5" name="radio" type="radio" className="custom-control-input" />
            <span className="custom-control-label">Mr</span>
        </label>
        <label className="custom-control custom-radio">
            <input id="radio6" name="radio" type="radio" className="custom-control-input" />
            <span className="custom-control-label">Mme</span>
        </label>
        <button type="button" className="btn btn-rounded btn-info">Ok</button>
    </div>
  );
};

export default ResponseCheckbox;