import React, { useState } from 'react';
import { validateEmail, validatePhone, saveResponse } from '../Utilities';

class MessageParser {
  constructor(actionProvider, state, stateDatas) {
    this.actionProvider = actionProvider;
    this.state = state;
    this.stateDatas = stateDatas;
  }

  parse = (message) => {

    //const lowerCaseMessage = (message.trim()).toLowerCase();
    message = message.trim();

    const botMessages = this.state.messages.filter(item => {
            return item.type === 'bot' && item.key != undefined;
    });
    const userMessages = this.state.messages.filter(item => {
            return item.type === 'user';
    });
    const nbrBotMessages = Object.values(botMessages).length;
    const lastBotMessage = botMessages[nbrBotMessages - 1];
    //console.log(botMessages)//console.log(lastBotMessage)

    var isValidate = true;
    if (lastBotMessage.hasOwnProperty('key') && lastBotMessage.key === "email") {
        //console.log(validateEmail(message));
        if (!validateEmail(message)) {
          isValidate = false;
          this.actionProvider.handleMessageError('Email');
        }
    } else if (lastBotMessage.hasOwnProperty('key') && lastBotMessage.key === "telephone") {
        //console.log(validatePhone(message));
        if (!validatePhone(message)) {
          isValidate = false;
          this.actionProvider.handleMessageError('Telephone');
        }
    }

    if (isValidate) {
      saveResponse(lastBotMessage.champ_id, message, lastBotMessage.key);
      //if ((parseInt(lastBotMessage.ordre)) < Object.values(getChamps()).length) {
      if (!lastBotMessage.is_last) {
        this.actionProvider.collecteData(parseInt(lastBotMessage.ordre) + 1);
      } else {
        this.actionProvider.remerciement();
      }
    }
    
  }
}

export default MessageParser;