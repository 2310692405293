// new file called GifCool.jsx
import CollImage from "../../../assets/img/are-ready.gif";

const GifCool = () => {

  return (
    <div>
      <img src={CollImage} />
    </div>
  );
};

export default GifCool;