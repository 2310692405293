import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles(() => ({
  gridPhotos: {
    // marginTop: "20px",
  },
  cadrePhotos: {
    // border: "3px solid #fdead3",
    borderRadius: "20px !important",
    position: "relative",
    // minWidth: "280px",
    // minHeight: "280px",
    display: "flex",
    justifyContent: "center"
  },
  gridTypoPhotos: {
    display: "flex",
    justifyContent: "center",
    margin: "25px 0"
  },
  typoPhotos: {
    fontSize: "1.4rem !important",
    fontWeight: "bold !important",
    color : "white",
    // visibility: "hidden",
  },
  buttonPhotos: {
    position: "fixed",
    bottom: "25px",    
    padding: "0 !important",
    paddingRight : "24px !important"
  },
  buttonPhotoLandscape : {
    position : "absolute",
  },
  containerButtonPhotosLandscape : {
    backgroundColor: "#fff",
  },
  containerButtonPhotos: {
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "10px 0",
    display: "flex",
    justifyContent: "space-around"
  },
  buttonMediumPhotosLandscape : {
    
  },
  buttonMediumPhotos: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center"
  },
  takeButtonPhotosLandscape : {
    borderRadius: "50% !important",
    width: "100px",
    height: "100px",
    position: "absolute !important",
    zIndex: 2,
    left: "-15px",
    top: "90px",
  },
  takeButtonPhotos: {
    borderRadius: "50% !important",
    width: "100px",
    height: "100px",
    position: "absolute !important",
    top: "-12px",
    zIndex: 2
  },
  buttonContainerFusions: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    textAlign: "center",
  },
  buttonFusions: {
    // border: "5px solid #fff !important",
    borderRadius: "5px !important",
    minWidth: "140px !important",
    height: "60px !important"
  },
  buttonSecondaryFusions: {
    backgroundColor: "#3c3c3c !important",
    marginRight: '10px !important',
  },
  loader : {
    backgroundColor: "red !important"
  },
  fusionedImg: {
    width: "auto"
  },
  blocError :{
    textAlign : "center",
    border : "2px solid red",
    background : "white",
    borderRadius : "10px",
    padding: "10px",
    color : "red",
    fontWeight : "bold",
  }
}));
