import { useRef, useEffect,forwardRef,useImperativeHandle } from "react";

// export default function Camera(props) {
// const Camera = forwardRef((props, ref) => {
const Camera = (props, ref) => {
  const devices = useRef(null);
  const localStream = useRef(null);
  const camera = useRef(null);
  const mounted = useRef(false);
  const canvasRef = useRef(null);
 

  useEffect(()=>{
    const init = async () => {
      if (!devices.current) {
        await loadDevices(); // load the camera devices list when the component is mounted
      }
      if (props.isActive === true) {
        playWithDesired();
      }
      mounted.current = true;
    }
    init();
  },[])

  useEffect(() => {
    if (mounted.current === true) {
      if (props.isActive === true) {
        playWithDesired();
      } else{
        stop();
      }
    }
  }, [props.isActive]);

  useEffect(() => {
    if (mounted.current === true) {
      if (camera.current && props.isActive === true) {
        if (props.isPause === true) {
          camera.current.pause();
        } else {
          camera.current.play();
        }
      }
    }
  }, [props.isPause]);

  useEffect(() => {
    if (props.isActive === true && localStream.current && mounted.current === true) {
      playWithDesired();
    }
  }, [props.desiredCamera, props.desiredResolution, props.facingMode]);

  // useEffect(() => {
  //   if (props.capture) {
  //     takepicture();
  //   }
  // }, [props.capture]);

  useImperativeHandle(ref, () => ({

    getPicture(options) {
        const canvas = canvasRef.current;
        canvas.width = options.widthSource;
        canvas.height = options.heightSource;

        // console.log(camera.current.videoWidth);
        // console.log(camera.current.videoHeight);

        // alert(`canvas ==> ${canvas.width}x${canvas.height}`);
        // alert(`source ==> ${options.widthSource}x${options.heightSource}`);

        
        canvas
          .getContext("2d")
          .drawImage(camera.current, 0, 0, canvas.width, canvas.height);

          // .drawImage(camera.current, 0, 0, canvas.width, canvas.height);
        
        var data =  canvas.toDataURL('image/png')

        return data;
    }
  }));

  

  const playWithDesired = async () => {
    if (!devices.current) {
      await loadDevices(); // load the camera devices list if it hasn't been loaded
    }
    let desiredDevice = getDesiredDevice(devices.current)
       
    if (desiredDevice) {
      let options = {};
      options.deviceId = desiredDevice;
      if (props.desiredResolution) {
        options.desiredResolution = props.desiredResolution;
      }
      if (props.facingMode) {
        options.facingMode = props.facingMode;
      }
      play(options);
    }else{
      props.setErrorNomera(true);
      //throw new Error("No camera detected");
    }
  }
    
  const getDesiredDevice = (devices) => {
    var count = 0;
    var desiredIndex = 0;
    for (var i=0;i<devices.length;i++){
      var device = devices[i];
      var label = device.label || `Camera ${count++}`;
      if (props.desiredCamera) {
        if (label.toLowerCase().indexOf(props.desiredCamera.toLowerCase()) != -1) {
          desiredIndex = i;
          break;
        } 
      }
    }
  
    if (devices.length>0) {
      return devices[desiredIndex].deviceId; // return the device id
    }else{
      return null;
    }
  }
    
  const play = (options) => {
    stop(); // close before play
    var constraints = {};
  
    if (options.deviceId){
      constraints = {
        video: {deviceId: options.deviceId},
        audio: false
      }
    }else{
      constraints = {
        video: {width:options.desiredResolution.width,height:options.desiredResolution.height},
        audio: false
      }
    }
  
    if (options.facingMode) {
      delete constraints["video"]["deviceId"];
      constraints["video"]["facingMode"] = { exact: options.facingMode };
    }

    if (options.desiredResolution) {
      // constraints["video"]["width"] = options.desiredResolution.width;
      // constraints["video"]["height"] = options.desiredResolution.height;
      constraints["video"]["width"] = {min: 640, ideal: options.desiredResolution.width, max: options.desiredResolution.width };
      constraints["video"]["height"] = { min: 400, ideal: options.desiredResolution.height, max: options.desiredResolution.height };
      // constraints["video"]["mandatory"] = {
      //   minWidth: options.desiredResolution.width,
      //   maxWidth: options.desiredResolution.width,
      //   minHeight: options.desiredResolution.height,
      //   maxHeight: options.desiredResolution.height
      // };
      var ratio = options.desiredResolution.width/options.desiredResolution.height;
      // console.log(ratio);
      constraints["video"]["aspectRatio"] = { ideal : ratio};


      // constraints["video"]["width"]["exact"] = options.desiredResolution.width;
      // constraints["video"]["height"]["exact"] = options.desiredResolution.height;

    }

    console.log(constraints);

    navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
      localStream.current = stream;
      // Attach local stream to video element
      camera.current.srcObject = stream;
    }).catch(function(err) {
      if (options.facingMode) { // facing mode not supported on desktop Chrome
        delete options["facingMode"];
        play(options);
      } else {
        console.error('getUserMediaError', err, err.stack);
      }
    });

    


    // getCoordonneFrame(options);
  }
     
  const stop = () => {
    try{
      if (localStream.current){
        const stream = localStream.current;
        const tracks = stream.getTracks();
        for (let index = 0; index < tracks.length; index++) {
          const track = tracks[index];
          track.stop();
        }
        if (props.onClosed) {
          props.onClosed();
        }
      }
    } catch (e){
      console.log(e);
    }
  };

  const loadDevices = async () => {
    const constraints = {video: true, audio: false};
    const stream = await navigator.mediaDevices.getUserMedia(constraints) // ask for permission
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    let cameraDevices = [];
    for (let i=0;i<mediaDevices.length;i++){
      let device = mediaDevices[i];
      if (device.kind === 'videoinput'){ // filter out audio devices
        cameraDevices.push(device);
      }
    }
    devices.current = cameraDevices;
    const tracks = stream.getTracks();
    for (let i=0; i<tracks.length;i++) {
      const track = tracks[i];
      track.stop();  // stop the opened camera
    }
    // if (props.onDeviceListLoaded) {
    //   props.onDeviceListLoaded(cameraDevices);
    // }
  }

  const onCameraOpened = () => {
    console.log("onCameraOpened");
    if (props.onOpened) {
      props.onOpened(camera.current,getCurrentCameraLabel());
      document.getElementById('video').classList.add('loaded');
      props.makeAllLoader(true);
    }
  }

  const getCurrentCameraLabel = () => {
    try {
      if (localStream.current) {
        const stream = localStream.current;

        let {width, height} = stream.getTracks()[0].getSettings();
        // alert(`${width}x${height}`); // 640x480

        return stream.getTracks()[0].label;
      }    
    } catch (error) {
      return "";
    }
  }

  const getCoordonneFrame = (options) => {

      // var video = document.getElementById('video');
      // let rect2 = video.getBoundingClientRect();

      var diffWitdh  = options.desiredResolution.width - props.desiredImage.width;
      var widthLeftRight = diffWitdh >  0 ? diffWitdh/2 : 0;
      
      var areaLeft = document.getElementById('areaLeft');
      areaLeft.setAttribute("style","width:" + widthLeftRight + "px;height:" +options.desiredResolution.height+"px");

      var areaRigth = document.getElementById('areaRigth');
      areaRigth.setAttribute("style","width:" + widthLeftRight + "px;height:" +options.desiredResolution.height+"px");


      var diffHeigth  = options.desiredResolution.height - props.desiredImage.height;
      var heightTopBottom = diffHeigth >  0 ? diffHeigth/2 : 0;

      var areaTop = document.getElementById('areaTop');
      areaTop.setAttribute("style","height:" + heightTopBottom + "px;width:" +options.desiredResolution.width+"px");

      var areaBottom = document.getElementById('areaBottom');
      areaBottom.setAttribute("style","height:" + heightTopBottom + "px;width:" +options.desiredResolution.width+"px");

      console.log(' diff width %d', diffWitdh);
  }

  return (
    <div 
      style={{ position:"relative", width:"100%", height:"100%", left:0, top:0 }}>
      <div className="contentarea">
        <div className="camera">
          {/* <div id="areaLeft"></div>
          <div id="areaRigth"></div>
          <div id="areaTop"></div>
          <div id="areaBottom"></div> */}

          <video
                id="video"
                style={{ objectFit:"cover", left:0, top:0 }}
                ref={camera} muted autoPlay={true} playsInline={true} 
                onLoadedData={onCameraOpened}>Le flux vidéo n'est pas disponible.</video>
        </div>
      </div>
      <canvas id="bufferCanvas"></canvas>
      <canvas ref={canvasRef} style={{ display: "none" }} id="canvas"> </canvas>
      <img alt="car" id="capturedImage" />
      {props.children}
    </div>
  )
  
};

export default forwardRef(Camera);
