import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  gridTopImage: {
    [theme.breakpoints.up('lg')]: {
      height: "auto",
      textAlign: "center",
    },
    [theme.breakpoints.down('md')]: {
      height: "auto",
      textAlign: "center",
      marginBottom:"15px",
    },
  },
  boxContainerSelections: {
    backgroundColor: "#fff",
    padding: "70px 70px",
    borderRadius: "5px",
    marginBottom:"130px",
  },
  gridMiddleImage: {
    padding: "0 20px !important",
    marginBottom: "10px",
  },
  typography: {
    textAlign: "center",
    padding: "0 20px !important",
  },
  gridButtonSelections: {
    display: "grid",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop:"30px",
  },
  photoWebcam: {
    marginTop:"30px",
  },
  goButton: {
    border: "5px solid #dcedff !important",
    padding: "20px 25px !important",
    fontWeight: "bold",
    fontSize: "1.2rem !important",
    borderRadius: "8px !important",
  },
  gridLastImage: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "25px"
  },
  typographyLastImage: {
    color: "#e2001a",
    fontWeight: "bold !important"
  },
  menuBottom: {
    display: "flex",
    backgroundColor: "lightgray",
    padding: "18px",
    justifyContent: "space-around",
    width: "100%",
    bottom: "0"
  },
  menuTypoBottom: {
    
    fontSize: "0.9rem !important",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline",
   },
  }
}));
