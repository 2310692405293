import React, { useEffect, useState } from "react";
import {Container, TextField} from "@mui/material";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import {saveResponse, uploadData, validateEmail} from '../Chatbot/Utilities';
import  { useNavigate } from 'react-router-dom';
import {Circles} from 'svg-loaders-react'
import Thanks from "./Thanks";


export default function Form() {

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorRegelement, setErrorRegelement] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMerci, setShowMerci] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("dataPhoto")) === null) {
        navigate("/");
        }
    }, []);

    const handleInput = () => {
        const emailVal = document.getElementById('email').value;
        if (!validateEmail(emailVal)) {
            setErrorEmail(true);
            setDisableSubmit(true);

            return false;
        } else {
            setErrorEmail(false);
            setDisableSubmit(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('submit');

        window.dataChamps = [];

        // saveResponse();
        const nameVal = document.getElementById('name').value;
        saveResponse(5782, nameVal, 'prenom');

        const emailVal = document.getElementById('email').value;
        if (validateEmail(emailVal)) {
            saveResponse(5784, emailVal, 'email');
        } else{
            setErrorEmail(true);
            setDisableSubmit(true);
            return false;
        }

        var optinEmail = document.querySelector('input[name="optinEmail"]:checked').value;
        saveResponse(5786, optinEmail, 'optin');

        var optinNewLetter = document.getElementById('id-optinNewsletter').checked ? 'oui' : 'non';
        saveResponse(5787, optinNewLetter, 'optin_newsletter');

        var optinRegelement = document.getElementById('optin-regelement').checked ? 'oui' : 'non';
        console.log(optinRegelement);
        if (optinRegelement === 'oui') {
            saveResponse(5788, optinRegelement, 'optin_reglement');
        } else {
            setErrorRegelement(true);
            setDisableSubmit(true);
            
            return false;
        }
        
        console.log('je passe ici');
        setLoading(true);
        setDisableSubmit(true);
        var res =  await uploadData();
        setLoading(false);
        setShowMerci(true);
    }

    
    return (
        <>
            {!showMerci ? 
            (
                <Container className="containerForm" maxWidth="sm" style={{ position: "relative" }}>
                    <div className="containerFormBlock" style={{ position: "relative" }}>
                        <h2 className="titreForm">Votre information</h2>
                        <form id="idForm" onSubmit={handleSubmit}>
                                <FormControl variant="standard" fullWidth >
                                    <InputLabel htmlFor="name">Nom</InputLabel>
                                    <Input id="name" error= {false} required/>
                                </FormControl>

                                <FormControl variant="standard" fullWidth margin="normal" >
                                    <TextField
                                        label="Email"
                                        id="email"
                                        name="email"
                                        error={errorEmail}
                                        helperText={errorEmail ? 'E-mail invalide' : ''}
                                        onChange={handleInput}
                                        variant="standard"
                                    />
                                </FormControl>

                                <FormControl margin="normal" fullWidth>
                                    <FormLabel id="id-option-email">Souhaites-tu <b>recevoir ta photo par email ?</b></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="id-option-email"
                                        name="optinEmail"
                                    >
                                        <FormControlLabel value="1" control={<Radio value={'oui'} required />} label="Oui bien sur ! 👍" />
                                        <FormControlLabel value="0" control={<Radio value={'non'} required />} label="Non ça ira ! ☕ " />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl margin="normal" fullWidth>
                                    <FormLabel id="id-option-newsletter">Pour rester informé(e) tout l’été de l’actu des festivals dans l’ouest (comptes rendus, photos...) <strong>inscris-toi à la Newsletter hebdomadaire "Festivals"</strong> 🎉</FormLabel>
                                    <FormControlLabel control={<Checkbox name="optinNewsletter" id="id-optinNewsletter" value={1} />} label="Oui, je m’inscris ! 🚀" />
                                </FormControl>

                                <FormControl margin="normal" fullWidth>
                                    <FormLabel id="id-optin-participation">Afin de finaliser ta participation, merci de <b>valider le règlement !</b> 👍</FormLabel>
                                    <FormControlLabel control={<Checkbox required id="optin-regelement" value={1} />} label=" Oui, j’accepte ! 🚀" />
                                </FormControl>

                                {<Button disabled={disableSubmit} type="submit" fullWidth variant="contained" startIcon={!loading && <SendIcon />}>
                                    {
                                        loading && (<Circles color="#FFF" className="loadingSend" />)
                                    }
                                    Envoyer
                                </Button>}
                        </form>
                    </div>
                </Container>
            ) 
            : 
            (<Thanks />)
            }
        </>
    )
}
