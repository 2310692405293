import React from "react";

import "../styles/options.css";

const OptionsOptin = (props) => {
  //console.log(props)
  const options = [
    { text: "Oui bien sûr ! 👍", handler:  () => props.actionProvider.getOptin('Oui', 'oui'), id: 'oui' },
    { text: "Non pas besoin ", handler:  () => props.actionProvider.getOptin('Non', 'non'), id: 'non' },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      id={`option-${option.id}`}
      //className="learning-option-button"
      className="btn btn-rounded btn-secondary btn-sm kl_btnOptionOuiNon"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container optionMessage">{optionsMarkup}</div>;
};

export default OptionsOptin;