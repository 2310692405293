import { useState } from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useStyle } from "./Photos.style";
import Fusions from "./Fusions";
import NewCaptures from "./NewCaptures";
import { BallTriangle } from 'svg-loaders-react'
import { storeDataImage } from '../Chatbot/Utilities';
import Cadre from '../../assets/img/cadre/cadre.png';

import ReactGA from 'react-ga';
import CustomCaptures from "./CustomCaptures";
ReactGA.initialize('G-2XFC6B6VKH');



export default function Photos(props) {
  const [capturedImg, setCapturedImg] = useState(null);
  const [loader, setLoader] = useState(null);
  const [sourcePhoto, setSourcePhoto] = useState(null)
  const classes = useStyle();
  const updateCapturedImage = (img, sourcePhoto) => {
    setCapturedImg(img);
    storeDataImage(img);
    setSourcePhoto(sourcePhoto);
  };
  const updateLoader = (bool) => {
    setLoader(bool);
  };

  console.log(loader);

  // ReactGA.initialize('G-2XFC6B6VKH');
  // ReactGA.pageview('/photo');


  return (
    <>
    <div className="customFlash hide" id="idCustomFlash"></div>
    <img src={Cadre} className='hide' alt="cadre"/>
    { <Grid className={classes.gridPhotos}>
      {loader && <div className="blocLoader"><BallTriangle className="chargment" /> </div>}
      <Box>
        {!capturedImg ? (
          <CustomCaptures 
            updateCapturedImage={updateCapturedImage}
            updateLoader={updateLoader}
            cadreName={props.cadreName}
          />
        ) : (
          <Fusions
            imgCapture={capturedImg}
            cadreName={props.cadreName}
            updateCapturedImage={updateCapturedImage} updateLoader={updateLoader} sourcephoto={sourcePhoto}
          />
        )}
      </Box>
    </Grid>}
    </>
  );
}
