import React from "react";
import { useNavigate } from "react-router-dom";
import {Container, Grid, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyle } from "./Contenu.style";
import classNames from "classnames";

export default (props) => {
  const navigate = useNavigate();
  const classes = useStyle();
  return (
    <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
    <Grid className={classes.containerTypo}>
      <Grid className={classNames(classes.containerTypo, classes.header)}>
        <Typography style={{textAlign: 'center'}} variant="h3" className={classes.headerTypo}>
        REGLEMENT DU JEU
        </Typography>
        <Button
          onClick={() => {
            if (!props.isPopup) {
              navigate("/");
            } else {
              document.getElementById('id_pageReglement').classList.add('hide');
            }
              
          }
        }
          variant="contained"
          size="small"
          className={classes.closeButton}
        >
          <CloseIcon />
        </Button>
      </Grid>
      <Grid className={classes.containerTypo}>
      <div>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>« Prends la pose – Festival Beauregard 2023 »</strong>
        </p>
        <h2 style={{ textAlign: "center" }}>&nbsp;</h2>
        <h2>ARTICLE 1 - ORGANISATION</h2>
        <p style={{ fontWeight: 400 }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400 }}>
          La Société Ouest-France ci-après désignée «&nbsp;l’Organisateur&nbsp;», dont
          le siège social est 10, rue du Breil, 35051 Rennes Cedex 9, organise du
          06/07/2023 au 09/07/2023 inclus, de l'opération intitulé « Prends la pose – Festival
          Beauregard 2023&nbsp;»
        </p>
        <p style={{ fontWeight: 400 }}>
          Ce jeu est gratuit et sans obligation d’achat.
        </p>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 2 - QUI PEUT PARTICIPER ?</h2>
        <p style={{ fontWeight: 400 }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400 }}>
          La participation à ce jeu est ouverte à toute personne résidant en France
          ainsi qu’aux personnes mineures jouant sous la responsabilité de leurs
          parents ou responsable légal. Sont exclus les membres du personnel
          Ouest-France, en activité ou retraités, ainsi que les dépositaires,
          diffuseurs, porteurs et correspondants des journaux Ouest France et
          «&nbsp;Dimanche Ouest-France » leurs conjoint, parents ou alliés vivant sous
          le même toit qu’eux.
        </p>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 3 - COMMENT JOUER ET GAGNER ?</h2>
        <p style={{ fontWeight: 400 }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400 }}>
          L'opération se déroule du 06/07/2023 au 09/07/2023 sur l’application
          «&nbsp;Prends la pose&nbsp;» à l’occasion de la présence du stand
          Ouest-France lors du Festival Beauregard 2023.
        </p>
        <p style={{ fontWeight: 400 }}>Le participant doit&nbsp;:</p>
        <p style={{ fontWeight: 400 }}>Se prendre en photographie
        <br/>
          Se connecter sur le site «&nbsp;festival.ouest-france.fr&nbsp;» ou sur
          l’application «&nbsp;Prends la pose&nbsp;» via le QR code (accessible via les outils de  communication sur le festival Beauregard)
        </p>
        <p style={{ fontWeight: 400 }}>
        Déposer ses coordonnées prénom, adresse électronique, et la photographie réalisée, 
        sur le formulaire (accessible sur le site « festival.ouest-france.fr » ou sur l’application «&nbsp;Prends la pose&nbsp;») via le QR code.
        </p>
        <p style={{ fontWeight: 400 }}>Cliquer sur le bouton « je valide ».<br/>
        Accepter de recevoir sa photo par mail (en cliquant sur le bouton «&nbsp;oui bien sûr&nbsp;»)<br/>
        Accepter le règlement (en cliquant sur le bouton de l’optin «&nbsp;oui j’accepte&nbsp;»).</p>
        <p style={{ fontWeight: 400 }}>
         Une surprise supplémentaire est réservée aux participants qui souhaitent mentionner leur date de naissance :
        </p>
        <p style={{ fontWeight: 400 }}>Si le participant a moins de 25 ans, il pourra bénéficier d’un abonnement gratuit au journal numérique Ouest-France<br/>Si le participant a plus de 25 ans, il pourra bénéficier d’un abonnement gratuit d’une durée de deux mois au journal numérique Ouest-France.
        </p>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 4 - DOTATION</h2>
        <p style={{ fontWeight: 400 }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400 }}>
          Les participants dûment inscrits à l'opération peuvent&nbsp;:
        </p>
        <p style={{ fontWeight: 400 }}>Récupérer la photographie qu’ils ont prise aux couleurs de Ouest-France et du festival Beauregard, par email.<br/>
        Repartir, s’il le souhaite avec leur photo imprimée, qu’ils pourront retirer sur le stand Ouest-France (entre le 6 et le 9 juillet 2023).</p>

        <p style={{ fontWeight: 400 }}>Le gain de l’opération intitulée « Prends la pose – Festival Beauregard 2023 » sera définitivement attribué après vérification que le joueur ayant remporté la dotation a bien respecté les règles du jeu.<br/>
        Les mineurs ne sont pas autorisés à venir retirer seuls les lots qu’ils ont gagnés. Ils doivent être impérativement être accompagnés d’un responsable légal ou d’un adulte détenant une autorisation parentale.</p>

        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 5 - IDENTIFICATION DU PARTICIPANT</h2>
        <p style={{ fontWeight: 400 }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400 }}>Le gagnant autorise par avance l’Organisateur à les photographier, à publier leur nom, prénom et photographie dans les journaux de l’organisateur et à les utiliser dans toutes les manifestations promotionnelles liées à la présente opération, sans que cette publication ou utilisation puisse ouvrir d’autres droits que le prix gagné. Il autorise également l’organisateur à mettre en ligne sur les sites édités par lui les photographies le représentant, à indiquer leur nom et prénom sur ces sites. <br/>
        Sous réserve de leur consentement exprès, les informations collectées sur les participants pourront être utilisées par la société organisatrice afin de les informer régulièrement sur les produits et services proposés par la société organisatrice</p>

        <p style={{ fontWeight: 400 }}>Les informations sur les joueurs recueillies par l’organisateur à l’occasion de l’opération ne feront  l’objet de communication à des tiers que pour satisfaire aux obligations légales et règlementaires.</p>

        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 6 - RESPONSABILITÉ DE L'ORGANISATEUR</h2>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <p style={{ fontWeight: 400 }}>La participation au jeu «Prends la pose – Festival Beauregard 2023 »  implique la connaissance et l’acceptation des caractéristiques et des limites de l’Internet, notamment en ce qui concerne les performances techniques, les temps de chargement et d’acheminement des réponses, l’absence de protection de certaines données contre des détournements et les risques de contamination par d’éventuels virus circulant sur le réseau. L’Organisateur décline toute responsabilité en cas de mauvaise utilisation ou d’incident lié à l’utilisation de l’ordinateur, de l’accès à Internet, de la mauvaise utilisation ou du dysfonctionnement des serveurs, de la ligne téléphonique ou de toute autre connexion technique, ou de non réception des documents, liée à un envoi autre que par la procédure de transfert prévue dans le cadre du présent jeu.</p>

        <p style={{ fontWeight: 400 }}>En cas d’incident technique empêchant les participants à l’opération ou altérant les informations transmises par les participants, et cela pour quelque raison que ce soit, la société organisatrice ne peut être tenue pour responsable du préjudice qui en découlerait pour les participants. La société organisatrice ne saurait encourir une quelconque responsabilité du fait du manquement par les participants aux règles sus mentionnées. .</p>

        <p style={{ fontWeight: 400 }}>Les photos seront adressées par mail et/ou remisent en main propre aux participants sur le stand Ouest—France. La société organisatrice décline toute responsabilité pour le cas où la photo n’arriverait pas à destination d’un fait de la fourniture par le gagnant d’une adresse erronée, en cas de perte, de dégradation ou de vol du lot durant la livraison. </p>
        <h2>&nbsp;</h2>
        <h2>ARTICLE 7 - DONNEES A CARACTERE PERSONNEL</h2>
        <p>7.1 Ouest France s’engage à mettre les meilleurs moyens en œuvre pour respecter l’ensemble des dispositions légales et réglementaires relatives à la protection des données à caractère personnel et notamment les dispositions du Règlement général européen de protection des données personnelles UE 2016/679 (RGPD) et celles de la loi Informatique et Libertés n°78-17 du 6 janvier 1978 modifiée (LIL). </p>
        <p style={{ fontWeight: 400 }}>Ouest-France s’engage à mettre les meilleurs moyens en œuvre pour : </p>
        
        <ul>
          <li>traiter les données uniquement pour la ou les seule(s) finalité(s) qui fait/font l’objet de la prestation à savoir la mise en place du jeu, la prise en compte de la participation, la prise de contact avec les gagnants, l’envoi de la dotation aux gagnants, l’élaboration de statistiques, l’envoi de communications sur les jeux organisés par Ouest-France et l’envoi d’offres commerciales. Le nom, le prénom et la photo des participants sera uniquement utilisée pour les opérations promotionnelles liées au présent jeu. </li> 
          <li>garantir la confidentialité des données à caractère personnel et à ce titre, veiller à ce que les personnes autorisées à traiter les données à caractère personnel s’engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité. </li>
        </ul>
        <p style={{ fontWeight: 400 }}>Les données personnelles ne sont en aucun cas transmises à des tiers à des fins de publicité et de promotion sans l’accord préalable des participants. En effet, Ouest-France s’engage à ne pas transférer les données à caractère personnel à des tiers hormis ses sous-traitants qui interviennent nécessairement pour répondre aux finalités du traitement. </p>
        <p style={{ fontWeight: 400 }}>Les données personnelles sont hébergées sur des serveurs situés en Union européenne. </p>
        <p style={{ fontWeight: 400 }}>7.2  Ouest-France conserve les données à caractère personnel pendant une période de 36 mois à compter de la collecte des données. Les données à caractère personnel sont ensuite archivées de manière à respecter les obligations légales d’Ouest-France puis supprimées. </p>

        <p style={{ fontWeight: 400 }}>7.3 Ouest-France rappelle au participant qu’il peut exercer ses droits d’accès, d’interrogation, de rectification, d’opposition, droit à l’effacement, droit à la limitation du traitement et droit à la portabilité des données auprès du Délégué à la Protection des Données (DPO) en lui envoyant un email à pdp@sipa.ouest-france.fr ou par voie postale à Délégué à la Protection des Données Personnelles – SIPA Ouest-France– 10 rue du Breil ZI Rennes Sud-Est 35000 Rennes. Néanmoins, Ouest-France informe le candidat qu’il pourra demander une preuve de son identité. Le délai de réponse à l’exercice de l’un ou l’autre de ces droits ne débutera qu’à compter de la réception de la preuve d’identité par Ouest-France.<br/>
        Le participant a également le droit d'introduire une réclamation auprès de la CNIL. </p>

        <p style={{ fontWeight: 400 }}>Pour plus d’information, vous pouvez consulter notre politique de données personnelles sur https://www.ouestfrance.fr/politiquedeprotectiondesdonneespersonnelles. </p>


        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 8 – RESPONSABILITE DE L'ORGANISATEUR</h2>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <p style={{ fontWeight: 400 }}>La responsabilité de l’Organisateur ne saurait être engagée si, pour un cas de force majeure ou indépendant de sa volonté, l’opération devait être modifiée, écourtée ou annulée. Aucune indemnité ne pourra être réclamée de ce chef. L’organisateur se réserve dans tous les cas la possibilité de prolonger la durée de l’opération de reporter la date de début annoncée et de modifier en conséquence les dates de communication du nom des gagnants de l’opération. </p>
        
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <h2>ARTICLE 9 – ACCEPTATION DU REGLEMENT</h2>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
        <p style={{ fontWeight: 400 }}>La participation à l’opération implique l’acceptation entière et sans réserve du présent règlement.  <br/>
        Il est consultable sur le site festival.ouest-france.fr. </p>

        <p style={{ fontWeight: 400 }}>&nbsp;</p>        
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
      </div>
      </Grid>
    </Grid>
    </Container>
  );
};
