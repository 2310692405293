import React from 'react';

const CustomMessage = (props) => {
  return (
    <div className='react-chatbot-kit-chat-bot-message'>
        {props.message} 
    </div>
  );
};

export default CustomMessage;