import { useState, useRef, useCallback, useEffect } from "react";
import { Container, Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useStyle } from "./Photos.style";
import ReactGA from "react-ga4";


export default function NewCaptures({ updateCapturedImage, updateLoader }) {
  const [mode, setMode] = useState("user");
  const [showErrorWebcam, setShowErrorWebcam] = useState(false);


  // const [imgSrc, setImgSrc] = useState(null);
  const [timer, setTimer] = useState(0);
  const idTimer = useRef(null);
  // const [nbrDevice, setNbrDevice] = useState(0);
  const classes = useStyle();
  //const [videoWidth, setVideoWidth] = useState(1126);
  //const [videoHeigth, setVideoHeigth] = useState(1182);
  const [videoWidth, setVideoWidth] = useState(1150);
  const [videoHeigth, setVideoHeigth] = useState(1182);

  
  const [orientation, setOrientation] = useState(0);

  const changeMode = () => {
    document.getElementById('videoCapture').classList.remove('borderVideo');
    //document.getElementById('videoCapture').classList.add('hide');
    //document.getElementById('buttonPhotos').classList.add('hide');
    // document.getElementById('prendPose').setAttribute("style","visibility:hidden");

    if (mode === "environment") {
      setMode('user');
    } else {
      setMode("environment");
    }
  }


  const clearTimer = () => {
    clearInterval(idTimer.current);
  };

  const startTimer = () => {
    // setTimer(4);
    // idTimer.current = setInterval(() => {
    //   setTimer((timer) => timer - 1);
    // }, 1000);
    takepicture();
  };



  useEffect(() => {
    startup();
    setSelect();

    if (timer === 0) {
      clearTimer();
      takepicture();
    }
  });



  const upladImage =  async (e) => {

    ReactGA.event({
      category: 'Photo',
      action: 'Upload une photo'
    });

    const file = e.target.files[0];
    var extension = file.type
    const validExtesion = ['image/x-png','image/gif','image/jpeg', 'image/png', 'image/jpg'];
    if (validExtesion.includes(extension)) {
      const base64 = await convertToBase64(file);
      const originalImage = new Image();
      originalImage.src = base64;
      originalImage.onload = () => {
        const smartcrop = require('smartcrop');
        smartcrop.crop(originalImage, { width: videoWidth, height: videoHeigth }).then(function(result) {
          console.log(result.topCrop);

          var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                crop = result.topCrop;

                canvas.width = videoWidth;
                canvas.height = videoHeigth;

            ctx.patternQuality = 'best';
            ctx.filter = 'best';
            ctx.drawImage(originalImage, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);
            var resultimg = canvas.toDataURL('image/jpeg');
            updateCapturedImage(resultimg, 2);
        });
      }

      updateLoader(true);
    }
  };

  const convertToBase64 = (file)  => {
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.onerror = error => reject(error);
      });
  };


  // Start new function
  const width = 600; // On met à l'échelle la photo pour avoir cette largeur
  let height = 0;    // On calcule cette valeur ensuite selon le flux d'entrée

  // |streaming| indique si le flux vidéo est en cours
  // Lorsqu'on commence, ce n'est pas le cas (false).

  let streaming = false;

  // On référence les éléments HTML qu'il faudra configurer ou contrôler.
  // Ils seront définis lors de la fonction startup().

  let video = null;
  let canvas = null;
  let bufferCanvas = null;

  let photo = null;
  let startbutton = null;

  const startup = () => {
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");
    bufferCanvas = document.getElementById("bufferCanvas");
    photo = document.getElementById("photo");
    startbutton = document.getElementById("startbutton");

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        video.srcObject = stream;       

        video.play();
      })
      .catch((err) => {
        console.error(`Une erreur est survenue : ${err}`);
      });

    video.addEventListener(
      "canplay",
      (ev) => {
        if (!streaming) {
          height = video.videoHeight / (video.videoWidth / width);

          // // Firefox a un bug où la hauteur ne peut pas être lue
          // // à partir de la vidéo. On prend des précautions.

          if (isNaN(height)) {
            height = width / (4 / 3);
          }

          video.setAttribute("width", width);
          video.setAttribute("height", height);
          // canvas.setAttribute("width", width);
          // canvas.setAttribute("height", height);
          streaming = true;
        }
      },
      false
    );

    // startbutton.addEventListener(
    //   "click",
    //   (ev) => {
    //     takepicture();
    //     ev.preventDefault();
    //   },
    //   false
    // );

    clearphoto();
  }

  const takepicture = () => {
      console.log('je passe ici frera');
      const context = canvas.getContext("2d");
      var coords = getCoords();
      if (coords.width && coords.height) {
        canvas.width = coords.width;
        canvas.height = coords.height;
        // context.drawImage(video, 0, 0, coords.width, coords.height);


        var bufferContext = bufferCanvas.getContext('2d');
        bufferCanvas.width = width;
        bufferCanvas.height = height;
        // bufferContext.drawImage(video, 0, 0);
        bufferContext.drawImage(video, 0, 0, width, height);

        console.log(coords.x);
        console.log('=> ' + coords.y);

        context.drawImage(bufferCanvas, 50, 50, coords.width, coords.height, 0, 0, coords.width, coords.height);

        const data = canvas.toDataURL("image/png");
        // alert(data);
        // photo.setAttribute("src", data);
        updateCapturedImage(data, 1);
        updateLoader(true);

        ReactGA.event({
          category: 'Photo',
          action: 'Prend une photo'
        });
        
      } else {
        clearphoto();
      }

  };

  const clearphoto = () => {
    // const context = canvas.getContext("2d");
    // context.fillStyle = "#AAA";
    // context.fillRect(0, 0, canvas.width, canvas.height);

    // const data = canvas.toDataURL("image/png");
    // photo.setAttribute("src", data);
  }

  const setSelect = () => {

    setTimeout(function() { 
      var selector = document.getElementById('areaRec');
      var video = document.getElementById('video');
      let rect = video.getBoundingClientRect();

      selector.style.width = (rect.width - 80) + 'px';
      selector.style.height = (rect.height - 80) + 'px';
      selector.style.display = 'block';
      selector.style.top =  '50px';
      selector.style.left = '50px';

      video.setAttribute( 'class', 'loaded' );

     }, 1500);
  }

  const getCoords = () => {

    var selector = document.getElementById('areaRec');
    let rect = selector.getBoundingClientRect();

    return {
      width: rect.width,
      height: rect.height,
      x: rect.x,
      y: rect.y,
    }; 
    
  }
  // End new fonction

  return (
    <div >
    <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
      <Grid className="blocPhotoCapture">
        <Box className="loading" id="id_contenaireCamera">
          
        <div className="camera">
    
            <div className="area" id="areaRec"></div>

            <video id="video">Le flux vidéo n'est pas disponible.</video>
        </div>

       


          <div className={timer === 0 ? "hide counter" : "counter"}>
            {timer}
          </div>
        </Box>
      </Grid>
      <Grid className={classes.gridTypoPhotos}>
        <Typography id="prendPose" className={classes.typoPhotos}>Prends la pose !</Typography>
      </Grid>
      { showErrorWebcam && (<Grid className={classes.blocError}>
      Impossible de prendre une photo. Si vous avez du webcam , veuillez autoriser la prise de photo.
      </Grid>) }
      <Container maxWidth="sm" data-dev={orientation} className={classes.buttonPhotos} id="buttonPhotos">
        <Box className={classes.containerButtonPhotos}>

          <Grid className={classes.buttonMediumPhotos} style={{ width: "30%" }} id="blocUploadPhoto">
            <IconButton aria-label="insertPhoto">
              <input type="file" className="photoUpload" onChange={(e) => upladImage(e)} accept="image/*" />
              <InsertPhotoIcon style={{ fontSize: "40px", color: "#007af9" }} />
            </IconButton>
          </Grid>
          <Grid className={classes.buttonMediumPhotos} style={{ width: "40%" }}>
            <Button
              variant="contained"
              onClick={startTimer}
              className={classes.takeButtonPhotos}
            >
              <CameraAltOutlinedIcon
                style={{ color: "#fff", fontSize: "45px" }}
              />
            </Button>
          </Grid>
          
          <Grid
            className={`${classes.buttonMediumPhotos + " kl_switchCam"}`}
            style={{ width: "30%" }}
            onClick={(e) => {
              e.preventDefault();
              changeMode();
            }}
          >
            <IconButton aria-label="switchCamera">
              <CameraswitchIcon
                style={{ fontSize: "40px", color: "#007af9" }}
              />
            </IconButton>
          </Grid>
        </Box>
      </Container>
    </Container>

    <canvas id="bufferCanvas"></canvas>
    <canvas id="canvas"></canvas>
    </div>
  );
}
