import React from 'react';
import axios from 'axios';
import FestivalYes from "../../../assets/img/yes.gif";
import GoodLuck from "../../../assets/img/good-luck.gif";
//import Modal from 'react-bootstrap/Modal';

export let enCoursEnvoi = 0;
export let errorEnvoiData = 0;

// 30 06 2023
export const getChamps = () => {
  // return getChampsLocal();
  
  var champs = [
    {
      "id": 12594,
      "value": "Quel est ton prénom ?",
      "type": "Prénom"
    },
    {
      "id": 12595,
      "value": "Ton adresse email (pour t'envoyer ta photo) ? ",
      "type": "email"
    },
    {
      "id": 12596,
      "value": "impression photo",
      "type": "optin"
    },
    {
      "id": 12597,
      "value": "Ta date de naissance ?",
      "type": "date"
    },    
    {
      "id": 12598,
      "value": "J’accepte le regement du jeux",
      "type": "optin_reglement"
    },
    // {
    //   "id": 12599,
    //   "value": "Rester informé(e) ?",
    //   "type": "optin_newsletter"
    // }
  ];

  return champs;
}

export const getChampsOld = () => {
  return getChampsLocal();
  var champs = [
    {
      "id": 11512,
      "value": "Quel est ton prénom ?",
      "type": "Prénom"
    },
    /*{
      "id": 11513,
      "value": "Ton nom de famille ?",
      "type": "nom"
    },*/
    {
      "id": 11514,
      "value": "Ton adresse email (pour t'envoyer ta photo) : ? 📧",
      "type": "email"
    },
    /*{
      "id": 11515,
      "value": "Et pour terminer, ton numéro de téléphone : ?",
      "type": "telephone"
    },*/
    {
      "id": 11516,
      "value": "impression photo",
      "type": "optin"
    },
    {
      "id": 'xxxx',
      "value": "Nous avons une surprise pour toi : pour cela donne nous ta date de naissance :",
      "type": "date"
    },    
    {
      "id": 11518,
      "value": "J’accepte le regement du jeux",
      "type": "optin_reglement"
    },
    {
      "id": 11517,
      "value": "Pour rester informé(e) tout l'été de l'actu des festivals dans l'Ouest, avoir les comptes rendus des festivals, les photos...alors inscris-toi à la Newsletter hebdomadaire ",
      "type": "optin_newsletter"
    }
  ];

  return champs;
}

export const getChampsLocal = () => {
  
  var champs = [
    {
      "id": 133,
      "value": "Quel est ton prénom ?",
      "type": "Prénom"
    },
    {
      "id": 134,
      "value": "Ton adresse email (pour t'envoyer ta photo) : ? 📧",
      "type": "email"
    },
    {
      "id": 135,
      "value": "impression photo",
      "type": "optin"
    },
    {
      "id": 136,
      "value": "Nous avons une surprise pour toi : pour cela donne nous ta date de naissance :",
      "type": "date"
    },    
    {
      "id": 137,
      "value": "J’accepte le regement du jeux",
      "type": "optin_reglement"
    },
    {
      "id": 138,
      "value": "Pour rester informé(e) tout l'été de l'actu des festivals dans l'Ouest, avoir les comptes rendus des festivals, les photos...alors inscris-toi à la Newsletter hebdomadaire ",
      "type": "optin_newsletter"
    }
  ];

  return champs;
}

export const getChampNewLetter= () => {
  const champ = {
      "id": 3322,
      "value": 'Pour rester informé(e) tout l\'été de l\'actu des festivals dans l\'Ouest, avoir les comptes rendus des festivals, les photos...alors inscris-toi à la Newsletter hebdomadaire "Festivals"',
      "type": "optin_newsletter"
  };

  return champ;
}

export const getCoordonnees = (type = "") => {

    var champs = getChamps();
    var nbrChamps= Object.values(champs).length;
    //console.log(nbrChamps);

    if (type !== "") {      
      champs = champs.map((item, key) => {
          item['ordre'] = key;
          item['is_last'] = (parseInt(key) + 1) == nbrChamps ? true : false;
          return item;
      });

      champs = champs.filter(item => {
            return item.type === type;
      });
      if (Object.values(champs).length) {
        champs = champs[0];
        if (champs.type == "civilite") {
          champs.widget = 'Options';
        }
        if (champs.type == "optin") {
          champs.widget = 'OptionsOptin';
        }
        if (champs.type == "optin_newsletter") {
          champs.widget = 'OptionsNewLetter';
        }

        // if (champs.type == "optin_reglement") {
        //   champs.widget = 'optin_reglement';
        // }

        

      }
    }
    // console.log(champs);

    return champs;
}

export const getCoordonneesByOrdre = (ordre = 0) => {

    var champs = getChamps();
    //console.log(champs);

    var nbrChamps = Object.values(champs).length;    
    champs = champs.map((item, key) => {
        item['ordre'] = key;
        item['is_last'] = (parseInt(key) + 1) == nbrChamps ? true : false;
        return item;
    });

    champs = champs.filter((item, key) => {
        return key === ordre;
    });

    if (Object.values(champs).length) {
        champs = champs[0];
        champs.ordre = ordre;
        
        if (champs.type == "civilite") {
          champs.widget = 'Options';
        }
        if (champs.type == "optin") {
          champs.widget = 'OptionsOptin';
        }
        if (champs.type == "optin_newsletter") {
          champs.widget = 'OptionsNewLetter';
        }
    }

    return champs;
}

const MessagePrenom = () => {
  return  (<>
    Pour commencer, <b>quel est ton prénom ?</b> 📝
    <div className='hide'>
        <img src={FestivalYes} className="hide" />
        <img src={GoodLuck} className="hide" />
    </div>
    </>
    
  );
}

const MessageNonFamille = () => {
  return (<>Ok, et <b>ton nom de famille ?</b> 📖</>)
}

const MessageTelephone = () => {
  return (<>Très bien ! Et pour terminer, <b>ton numéro de
    téléphone ?  📞 </b></>)
}

const MessageAdressEmail = () => {
  return (
    <>
       Pour recevoir ta photo, <b>quel est ton email ?</b> ✉️ 
    </>
  )
}

export const setMessage = (champ) => {
  let message = champ.value;
  // if (champ.type == 'email') {

  // }

  if (champ.ordre === 0) {
    message = <MessagePrenom />;
  //} else if (champ.ordre === 1) {
  } else if (champ.type === "nom") {
    message = <MessageNonFamille />;
  //} else if (champ.ordre === 2) {
  } else if (champ.type === "email") {
    message = <MessageAdressEmail />;
  //} else if (champ.ordre === 3) {
  } else if (champ.type === "telephone") {
    message = <MessageTelephone />;
  }

  return message;
}


export const setMessageOld = (champ) => {
  const ordre = champ.ordre;// champ.id;
  let message =
    ordre == 0
      ? ""
      //? "Pour <b>valider votre participation</b> à notre concours, il nous faut connaitre votre "
      : "Ton ";
  //let msg = "Pour valider ta participation à notre concours, il nous faut connaitre";

  message =
    champ.type == "optin"
      ? champ.value
      : message + " " + champ.value.toLowerCase();

  message = 
      message.includes('?') 
        ? message 
        : message + " : ";

  // Je mets ça ici car il me faut retourner le jsx sinon le bolad ne marche pas
  // if (champ.ordre == 0) {
  //   message = <span><b>Pour tenter de gagner un pass 3 jours pour l’édition prochaine et recevoir ta photo, </b> nous avons besoin de quelques infos !  🤗 </span>;
  // }

  return message;
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  //const regex = /^\+33|0|0033\d{9}$/;
  const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  return regex.test(String(phone).toLowerCase()); //phone.match(regex);
};

export const storeDataImage = (image) => {

  var dataImg = {};
  dataImg['captured_img'] = image;
  dataImg['date_photo'] = dateNow();
  dataImg['name_photo'] = setPhotoName();
  //console.log(window.dataPhoto)
  window.dataPhoto = dataImg;
  window.dataDowload = dataImg;

  localStorage.setItem("dataPhoto", JSON.stringify(dataImg));
}

export const storeImageFusionned = (image) => {

  //var dataImg = JSON.parse(localStorage.getItem("dataImg"));
  //dataImg['fusioned_img'] = image;
  window.dataPhoto.fusioned_img = image;
  window.dataDowload.fusioned_img = image;
  //localStorage.setItem("dataImg", JSON.stringify(dataImg));
}

export const resetDataImage = (image) => {
  localStorage.setItem("dataImg", JSON.stringify([]));
}

export const dateNow = () => {
  const today = new Date();
  const date = today.getFullYear()+'-'+
                addZero((today.getMonth()+1), 2)+'-'+
                addZero(today.getDate(), 2)+' '+
                addZero(today.getHours(), 2)+':'+
                addZero(today.getMinutes(), 2)+':'+
                addZero(today.getSeconds(), 2);
  
  return date;
}

export const setPhotoName = () => {
  const today = new Date();
  const photoName = today.getFullYear()+'_'+
                addZero((today.getMonth()+1), 2)+'_'+
                addZero(today.getDate(), 2)+'_'+
                addZero(today.getHours(), 2)+'_'+
                addZero(today.getMinutes(), 2)+'_'+
                addZero(today.getSeconds(), 2)+'_'+
                addZero(today.getMilliseconds(), 5);

  return photoName;
}

export const addZero = (x,n) => {
  while (x.toString().length < n) {
    x = "0" + x;
  }
  return x;
}

export const getAge = (dateBirthUser) => {
  // var dob = new Date("06/24/2008");  
  var dob = new Date(dateBirthUser);  
  //calculate month difference from current date in time  
  var month_diff = Date.now() - dob.getTime();
  //convert the calculated difference in date format  
  var age_dt = new Date(month_diff);
  //extract year from date      
  var year = age_dt.getUTCFullYear();  
  //now calculate the age of the user  
  var age = Math.abs(year - 1970); 

  return age;
}

export const saveResponseOld = (idChamp, response, type) => {
  //{id : , value : , type : }

  var res = {id : idChamp, value : response, type : type};
  var userResponses = JSON.parse(localStorage.getItem("user_responses"));
  userResponses[idChamp] = res;
  //localStorage.setItem("user_responses", JSON.stringify(userResponses));
  //console.log(userResponses)
  
  var champs = userResponses.filter((item) => {
    return item !== null;
  });
  console.log(champs)
  champs = champs.map((item, key) => {
      delete item['type'];
      item.value = (item.value.toLowerCase() === "non" ) ? '0' : item.value;
      item.value = (item.value.toLowerCase() === "oui" ) ? '1' : item.value;

      return item;
  });
  localStorage.setItem("user_responses", JSON.stringify(champs));
  //postResponse(champs);
}

export const saveResponse = (idChamp, response, type) => {

  var res = {id : idChamp, value : response, type : type};
  var dataResponse = window.dataChamps;
  dataResponse[idChamp] = res;
  
  var champs = dataResponse.filter((item) => {
    return item !== null;
  });
  console.log(champs)
  champs = champs.map((item, key) => {
      delete item['type'];
      item.value = (item.value.toLowerCase() === "non" ) ? '0' : item.value;
      item.value = (item.value.toLowerCase() === "oui" ) ? '1' : item.value;

      return item;
  });
  window.dataChamps = champs;
}

const afterEnvoi = () => {
  enCoursEnvoi--;
  document.getElementById('textLoaderEvnoi').innerHTML = 'Envoi terminé et effectué avec succès ! 🤩';
  document.getElementById("containerLoader").classList.add('hide');
  document.getElementById("chekImageLoader").classList.remove('hide');
  console.log(' ==> errorEnvoiData' + errorEnvoiData);
  if (errorEnvoiData > 0) {
    console.log('envoi data maintenant');
    uploadData();
  }
}

export const uploadPhoto = () => {
  const upload = async () => {

      var dataPhoto = window.dataPhoto;
      var data = {
          borne_id: "VB",
          code_logiciel: "S3AS",// "SN9V", //"KWF9",//SSMY SSVD
          date_photo: dataPhoto['date_photo'], 
          name_photo: dataPhoto['name_photo'] + "A.jpg", 
          save_date: dataPhoto['date_photo'],
          type: "photo-cropped"
      };

      //const urlUpload = "http://localhost:8765/fr/NextCloudHooks/uploadPhotoVB/";
      const urlUpload = "https://booth.selfizee.fr/fr/NextCloudHooks/uploadPhotoVB/";
      // const urlUpload = "http://boothdev.selfizee.test/fr/next-cloud-hooks/upload-photo-vB"; 

      enCoursEnvoi++;
      await fetch(urlUpload, {
          method: "POST",
          headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data : data,
            customized : dataPhoto['captured_img']
          }),
      })
      .then(res => consume(res.body))
      .then(response => response.json())
      .then(res => {
        console.log(res)
        afterEnvoi();
      })
      .catch(err => {
        console.log(err);
        afterEnvoi();
      });
  }
  upload();
}

export const donwloadImage = () => {
  var dataPhoto = window.dataDowload.fusioned_img;
  // console.log(dataPhoto);
  const a = document.createElement('a')
  a.target = '_blank';
  a.download = 'photo-beauregard.jpg'
  a.href = dataPhoto;
  a.click();
}

const consume = (stream, total = 0) => {
  console.log(stream);
  while (stream.state === "readable") {
    var data = stream.read()
    total += data.byteLength;
    console.log("received " + data.byteLength + " bytes (" + total + " bytes in total).")
  }
  if (stream.state === "waiting") {
    stream.ready.then(() => consume(stream, total))
  }
  return stream.closed
}

const fucth = (url, opts={}, onProgress) => {
  return new Promise( (res, rej)=>{
      var xhr = new XMLHttpRequest();
      xhr.open(opts.method || 'get', url);
      for (var k in opts.headers||{})
          xhr.setRequestHeader(k, opts.headers[k]);
      xhr.onload = e => res(e.target.responseText);
      xhr.onerror = rej;
      if (xhr.upload && onProgress)
          xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.send(opts.body);
  });
}

export const uploadData = () => {
  const upload = async () => {

    var dataPhoto = JSON.parse(localStorage.getItem("dataPhoto")); //window.dataPhoto;
    console.log(dataPhoto);
    if (dataPhoto) {
      var data = {
          borne_id: "VB",
          code_logiciel: "S3AS",// "SN9V",// "KWF9",
          date_photo: dataPhoto['date_photo'], 
          name_photo: dataPhoto['name_photo'] + ".jpg", 
          save_date: dataPhoto['date_photo'],
          type: "photo-customized",
          champs : window.dataChamps, //JSON.parse(localStorage.getItem("user_responses"))
      };
      //console.log(data);return false;
      const urlUpload = "https://booth.selfizee.fr/fr/NextCloudHooks/uploadDataVB"; 
      // const urlUpload = "http://boothdev.selfizee.test/fr/next-cloud-hooks/upload-data-vB";     

      //enCoursEnvoi++;
      await fetch(urlUpload, {
          method: "POST",
          headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data : data
          }),
      })
      .then(res => consume(res.body))
      .then(response => response.json())
      .then(res => {
        //enCoursEnvoi--;
        console.log(res);
        if (res.success) {          
          console.log(res)
          localStorage.removeItem("dataPhoto")
          window.dataPhoto = [];
          window.dataChamps = [];
          errorEnvoiData = 0;
          return true;
        } else {
            //return false;
            errorEnvoiData++;
        }
      })
      .catch(err => {
        console.log(err);
        //enCoursEnvoi--;
        errorEnvoiData++;
      });
    }
  }
  return upload();
}

export const uplodaPhotoOld = (type = 'photo') => {
  const upload = async () => {

      var dataPhoto = window.dataPhoto;
      var data = {
          borne_id: "VB",
          code_logiciel: "SSMY",
          //count_print: 1,
          date_photo: dataPhoto['date_photo'], //"2021-12-31 18:21:29",
          //filter: "compo",
          name_photo: dataPhoto['name_photo'] + (type != 'photo' ? "A" : "" ) + ".jpg", //"2021_12_31_18_21_32_00007.jpg", 2021-5-17-56376A.jpg
          //print_date: "2021-12-31 18:21:49",
          //remaining_sheets: 4,
          save_date: dataPhoto['date_photo'],
          //software_version: "1.1.5",
          type: (type == "photo") ? "photo-customized" : "photo-original"
      };
      if (type == "photo") {
        data['champs'] = JSON.parse(localStorage.getItem("user_responses"));//champs;
      }

      var photo = (type == "photo") ? dataPhoto['fusioned_img'] : dataPhoto['captured_img'];

      //console.log(window.dataPhoto);
      //console.log(data);return false;
      /*var photo =  'iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAMAAAB/2U7WAAAABl'
              + 'BMVEUAAAD///+l2Z/dAAAASUlEQVR4XqWQUQoAIAxC2/0vXZDr'
              + 'EX4IJTRkb7lobNUStXsB0jIXIAMSsQnWlsV+wULF4Avk9fLq2r'
              + '8a5HSE35Q3eO2XP1A1wQkZSgETvDtKdQAAAABJRU5ErkJggg==';*/

      //const urlUpload = "https://apidev.pikcloud.io/api/files/upload";
      //const urlUpload = "http://apidev.pikcloud.test/api/files/upload";

      const urlUpload = "http://localhost:8765/fr/NextCloudHooks/uploadPhotoVB/";

      await fetch(urlUpload, {
          method: "POST",
          headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
              //'Content-Type': 'multipart/form-data'
              //'Authorization' :  `Bearer ${tokenSession}`
          },
          body: JSON.stringify({
            data : data,
            customized : photo, 
            source : 'vb'
          }),
      })
      .then(res => consume(res.body))
      .then(response => response.json())
      .then(res => {
        console.log(res)
        localStorage.setItem('fusioned_img', '');
      })
      .catch(err => {
        console.log(err);
      });
      
      /*const req = await axios.post(urlUpload, {
        data : data,
        customized : photo, 
        source : 'vb'
      }, {
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          //'Content-Type': 'multipart/form-data'
          //'Authorization' :  `Bearer ${tokenSession}`
      }
      })
        .then(res => {
          console.log(res);
        })*/
  }
  return upload();
}