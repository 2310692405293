import mergeImages from "merge-images";
import { useState, useEffect } from "react";
import { Container, Box, Grid, Typography, Button } from "@mui/material";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useStyle } from "./Photos.style";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { storeImageFusionned, uploadPhoto } from '../Chatbot/Utilities';
import ReactGA from "react-ga4";
import Cadre from '../../assets/img/cadre/cadre.png';
import Cadre2 from '../../assets/img/cadre/cadre2.png';
import Cadre3 from '../../assets/img/cadre/cadre3.png';
import {isMobile} from 'react-device-detect';


export default function Fusions(props) {
  const [fusionedImg, setFusionedImg] = useState(null);
  const classes = useStyle();
  const navigate = useNavigate();
  const setFusionnedImage = (src) => {
    setFusionedImg(src);
    props.updateLoader(false);
    storeImageFusionned(src);
  };
  // console.log(1110/2-props.imgCapture.width/2);
  useEffect(() => {
    var i = new Image();
    // var x = 65;
    // var y = 65;

    i.src = props.imgCapture;
    // console.log(i.width);
    // var x = 1110/2 - i.width/2;
    // var y = 1110/2 - i.height/2;
    // console.log(x);

    i.onload = function () {
      // const imgWidth = i.naturalWidth;
      // const imgHeight = i.naturalHeight;

      // console.log('imgWidth: ', imgWidth);
      // console.log('imgHeight: ', imgHeight);

      var realCadre = Cadre;
      var x = 38;
      var y = 40;

      if (props.cadreName === 'cadre2.png') {
        realCadre = Cadre2;
        x = 50;
        y = 60;
      } else if (props.cadreName === 'cadre3.png') {
        realCadre = Cadre3;
        x = 50;
        y = 60;
      }

      mergeImages(
        [
          {
            src: props.imgCapture,
            x: x,
            y: y,
          },
          {
            src: realCadre, //Cadre,
            x: 0,
            y: 0,
          },
        ],
        {
          format: "image/jpeg",
          //quality : 0.5,
        }
      )
        .then((src) => setFusionnedImage(src))
        .catch((err) => console.log(err));
    };
  }, []);

  const retour = () => {
    props.updateCapturedImage(null, null);
    ReactGA.event({
      category: 'Photo',
      action: 'Retout sur prise  photo'
    });
  };

  const valider = () => {
    uploadPhoto({cadreName : props.cadreName});
    //props.sourcephoto === 1 ? navigate("/chatbot") : navigate("/form");
    navigate("/chatbot");
    ReactGA.event({
      category: 'Photo',
      action: 'Valide la photo'
    });
  }

  useEffect(() => {
    const fusionedImg = document.getElementById("fusionedImg");
    if (fusionedImg)
      if (isMobile) {
        // fusionedImg.style = `width: ${
        //   document.documentElement.clientWidth - 25
        // }px; height:auto;`;
        fusionedImg.style = `height: calc(100vh - 219px); width:auto;max-width:100%;`;
      } else {
        // fusionedImg.style = `height: ${
        //   document.documentElement.clientHeight - 220
        // }px`;
        fusionedImg.style = `height: calc(100vh - 219px); width:auto;max-width:100%;`;
      }
      
  }, [fusionedImg]);

  return (
    <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
      {fusionedImg && (
        <>
          <Grid
            className="blocVisualiationPhoto"
            style={{ textAlign: "center" }}
          >
            <img
              src={fusionedImg}
              id="fusionedImg"
              className={classes.fusionedImg}
              alt="The Fusioned"
            />
          </Grid>
          <Grid>
            <Typography className={classnames("photoVousPlait")}>
              La photo te plait ?
            </Typography>
          </Grid>
          <Grid className={classes.buttonContainerFusions}>
            <Box className="container2btn">
              <Button
                className={classnames(
                  classes.buttonFusions,
                  classes.buttonSecondaryFusions
                )}
                variant="contained"
                onClick={retour}
                size="large"
              >
                <SettingsBackupRestoreOutlinedIcon
                  style={{ fontSize: "40px" }}
                />
              </Button>
              <Button
                className={classes.buttonFusions}
                variant="contained"
                size="large"
                onClick={valider}
                style={{ backgroundColor:"#fe4d4e" }}
              >
                <CheckCircleOutlinedIcon style={{ fontSize: "40px" }} />
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Container>
  );
}
