import React from "react";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";

const validateInput = (message) => {
    
    //console.log(message);
    if (message == "") {
      return false;
    }

    return true;
}


export default validateInput;