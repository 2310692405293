import { Container, Box, Grid, Typography, Button } from "@mui/material";
import GoodLuck from "../../assets/img/good-luck.gif";
import DownloadIcon from '../../assets/img/download.svg';
import HomeIcon from '../../assets/img/home.svg';
import {donwloadImage} from '../Chatbot/Utilities';


export default function Thanks(props) {


  return (
    <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
        <div className="containerFormBlock">
            <div className="blocMessageThanks">
                <span>🙏<b>Félicitations, ta participation est bien prise en compte !</b> <br/>On croise les doigts pour le tirage au sort ! 🤞</span>
                <div className='blocGoodLuckImg'>
                <img src={GoodLuck} className="GoodLuckImg" alt="Goole luck"/>
                </div>
            </div>
            <div className="blocMessageThanks">
                <span>N'hésite pas à <b>partager ta photo</b> sur tes réseaux sociaux favoris ! ❤️ 🔥‍</span>
                <button 
                className='btn btn-telecharment btn-sm btn-rounded'
                onClick = {(e) => {
                    donwloadImage();
                }}
                >
                <img className='iconDownload' src={DownloadIcon} alt="download" /> Télécharger
                </button>
            </div>
            <div className="blocMessageThanks">
                <span>Bon salon 🎉 et <b>retrouve- nous sur le stand</b> pour encore + de surprises et d’animations ! 👍</span>
                <a 
                className='btn btn-retour btn-sm btn-rounded'
                href='/'
                >
                    <img className='iconBack' src={HomeIcon} alt="retour"/> Retour à l’accueil
                </a>
            </div>
        </div>
    </Container>
  );
}
