import { getCoordonnees, setMessage, saveResponse, getCoordonneesByOrdre, uploadData, donwloadImage, enCoursEnvoi, getAge, addZero} from '../Utilities';
import SendIcon from '../../../assets/img/send-svgrepo-com.svg';
import FestivalYes from "../../../assets/img/yes.gif";
import GoodLuck from "../../../assets/img/yes.gif";
import EzGif from "../../../assets/img/ezgif.gif";
import DownloadIcon from '../../../assets/img/download.svg';
import HomeIcon from '../../../assets/img/home.svg';
import Done from '../../../assets/img/done.svg';
import DatePickerInput from "../Template/DatePickerInput";
import DateInput from "../Template/DateInput";
import DateInputSelect from "../Template/DateInputSelect"

import { Modal, Button } from 'react-bootstrap';
import React, { useState } from 'react';


class ActionProvider {

   constructor(
    createChatBotMessage,
    setStateFunc,
    createClientMessage,
    stateRef,
    createCustomMessage,
    ...rest
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.stateRef = stateRef;
    this.createCustomMessage = createCustomMessage;
  }

  handleHello = () => {
    const botMessage = this.createChatBotMessage('Hello. Nice to meet you.');
    this.updateChatbotState(botMessage);
  }

  handleDog = () => {
    const botMessage = this.createChatBotMessage(
      "Here's a nice dog picture for you!",
      {
        widget: 'dogPicture',
      }
    );
    this.updateChatbotState(botMessage);
  }

  updateChatbotState = (message) => {

    this.setState(prevState => ({
      ...prevState, messages: [...prevState.messages, message]
    }))
  }

  handleMessageError = (type = '') => {
    let titre = type ;
    console.log('==> '+ type);
    if (type === 'Telephone') {
      titre = 'Téléphone';
    } else if (type === 'Email') {
      titre = 'E-mail';
    }
    const greetingMessage = this.createChatBotMessage(`⛔ ${titre} invalide. Merci de réessayer.`);
    this.updateChatbotState(greetingMessage);
    this.desactiveForm();
        
    //reinit form
    setTimeout( () => {
      this.reactiveForm();
    }, 1000);
  } 


  disableForm = () => {
    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const form = document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0];
    const btnSubmit = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

    //input.backgroundColor = "#fff";
    form.classList.add('disable-message');
    input.disabled = true;
    btnSubmit.disabled = true;
  }

  desactiveForm = () => {
    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const form = document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0];
    const btnSubmit = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

    form.classList.add('invalide-message');
    input.disabled = true;
    btnSubmit.disabled = true;
    input.placeholder = "Ce format n'est pas valide.";
  }

  reactiveForm = () => {
    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const form = document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0];
    const btnSubmit = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

    input.placeholder = "Ta réponse ...";
    input.disabled = false;
    btnSubmit.disabled = false;
    form.classList.remove('invalide-message');
  }

  /*askTelephone() {
    const botMessage = this.createChatBotMessage(setMessage(getCoordonnees('telephone')),
    { 
      key : getCoordonnees()[1].type,
      order : 1,
      required : false
   });
    //console.log(botMessage)
    this.updateChatbotState(botMessage);
  }*/

  collecteData = (ordre) => {
    console.log(ordre)
    //console.log(this.stateRef)
    //const data = getCoordonnees(type);
    const data = getCoordonneesByOrdre(ordre);
    console.log(data);
    var options = { 
        key : data.type,
        champ_id : data.id,
        ordre : data.ordre,// data.id,
        is_last : data.is_last,
        required : false,
        delay : 0,
        withAvatar: true,
    };

    if (data.type === 'email') {
      // const botMessageNote = this.createChatBotMessage("👌 C'est noté !");
      // this.updateChatbotState(botMessageNote);
      // options.delay = 2000;
    } else if (data.type == "civilite") {
      options.widget = 'Options';
      this.disableForm();
    } else if (data.type == "optin") {
      //const merciInfo = this.createChatBotMessage("🤙 C’est terminé pour les infos !");
      //this.updateChatbotState(merciInfo);
      //options.delay = 2000;

      this.disableForm();
    } else if (data.type === "optin_newsletter" || data.type === "optin_reglement" ) {
      

      this.disableForm();
    } else {
      this.reactiveForm();
    }
    //console.log(options)

    var message = setMessage(data);
    // if (ordre === 2) {
    //   message = this.messageAdressEmail();
    // } else if(ordre === 4) {
    //   message = this.optinEmailMessage();
    // } else if (ordre === 5) {
    //   message = this.messageTelephone();
    // }

    //if (ordre === 3) {
    if (data.type === "telephone") {
        document.querySelector(".react-chatbot-kit-chat-input").setAttribute('type', 'number');
    } else {
        document.querySelector(".react-chatbot-kit-chat-input").setAttribute('type', 'text');
    }


    //if(ordre === 4) {
    if (data.type === "optin") {
      message = this.optinEmailMessage();
    }

    if (data.type === "optin_newsletter") {
      message = this.optinNewLetterMessage();
    }

    if (data.type === "optin_reglement") {
      message = this.optinRegelementMessage();
    }

    if (data.type == "date") {
      // options.widget = 'DateInput';
      message = this.optinDateMessageFirst()
      this.disableForm();
    }


    var botMessage = this.createChatBotMessage(message, options);    
    this.updateChatbotState(botMessage);

    if (data.type == "date") {
      // options.widget = 'DateInput';
      message = this.optinDateMessage()
      this.disableForm();
      setTimeout(() => {
        var botMessage = this.createChatBotMessage(message, options);    
        this.updateChatbotState(botMessage);
      }, 2000)
    }

  }

  optinEmailMessage = () => {
    const options = [
      { text: "Oui bien sûr ! 👍", handler:  () => this.getOptin('Oui bien sûr !  👍', 'oui'), id: 'oui' },
      { text: "Non ça ira ! ❌ ", handler:  () => this.getOptin('Non ça ira ! ❌', 'non'), id: 'non' },
    ];
  
    const optionsMarkup = options.map((option) => (
      <button
        id={`option-${option.id}`}
        className="btn btn-rounded btn-secondary btn-sm btnOptinEmail"
        key={option.id}
        onClick={option.handler}
      >
        {option.text}
      </button>
    ));
  
    return  (<div className="learning-options-container">
        <span>Souhaites-tu<b> faire imprimer gratuitement ta photo et venir la chercher sur le stand Ouest-France ? </b></span>
        {optionsMarkup}
        </div>);
  }

  optinDateMessageFirst = () => {
    return  (<div className="learning-options-container">
              <p>
                <b> Nous avons une surprise pour toi 🎁! </b> <br/><br/>
                <span>Afin de lire toute l’actu des festivals et + encore,
                  <b> nous t’offrons un abonnement au journal numérique Ouest-France ! ☺</b>
                </span>
              </p>
        </div>);
  }

  optinDateMessage = () => {
    const dataYear = ['AAAA',
        /*2023,2022 ,2021,2020 ,2019 ,2018 ,,2017 ,2016 ,2015 ,2014 ,2013 ,2012,*/2011,2010 ,2009,2008,2007,2006 ,2005 ,2004,2003,2002 ,2001 ,2000 ,1999,1998,1997,
        1996,1995 ,1994,1993,1992,1991 ,1990 ,1989,1988 ,1987 ,1986 ,1985 ,1984,1983,1982 ,1981,1980,1979,1978,1977,1976,1975,1974,1973,1972,1971,1970,1969,1968,
        1967,1966,1965,1964,1963,1962,1961,1960,1959,1958,1957,1956,1955,1954,1953,1952,1951,1950
    ];
    const dataMonth = ['MM','01','02','03','04','05','06','07','08','09',10,11,12];
    const dataDay = ['JJ','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    const today = new Date();

    let valDay = 'JJ'
    let valMonth = 'MM'
    let valYear = 'AAAA'
    const handleInputDay = (value) => {
      // console.log(value)
      valDay = value
      checkInput()
    };
    const handleInputMonth = (value) => {
      // console.log(value)
      valMonth = value
      checkInput()
    };
    const handleInputYear = (value) => {
      // console.log(value)
      valYear = value
      checkInput()
    };

    const checkInput = () => {      
      if (valDay != 'JJ' && valMonth != 'MM' && valYear != 'AAAA') {
        document.getElementById('id_validateDate').classList.remove('disabled');
        document.getElementById('id_validateDate').classList.remove('disableCss');
      } else {
        document.getElementById('id_validateDate').classList.add('disabled'); 
        document.getElementById('id_validateDate').classList.add('disableCss');         
      }
    }

    return  (<div className="learning-options-container">
                <span>Pour cela,<strong> donne nous ta date de naissance :</strong></span>
                <div className='chb-grp-date'>
                  <DateInputSelect 
                    data={dataDay} 
                    dataId='id_dayDate' 
                    hasDivider={true} 
                    defaultValue={today.getDate()}
                    onInputChange={handleInputDay}
                  />   
                  <DateInputSelect 
                    data={dataMonth} 
                    dataId='id_monthDate' 
                    hasDivider={true} 
                    defaultValue={today.getMonth()+1} 
                    onInputChange={handleInputMonth}
                  />    
                  <DateInputSelect 
                    data={dataYear} 
                    dataId='id_yearDate' 
                    hasDivider={false} 
                    defaultValue={today.getFullYear()}
                    onInputChange={handleInputYear}
                  />  
                </div> 
                <button
                    id='id_validateDate' 
                    className='btn btn-rounded btn-primary btn-sm m-t-20 disableCss disabled'
                    onClick = {(e) => {
                        // e.preventDefault();
                        this.saveDate();
                    }}
                  >
                  <img className='iconSend' src={SendIcon} /> Envoyer
                </button>
            </div>);
  }

  saveDate = () => {
    // console.log('value')
    const day = document.getElementById('id_dayDate').value
    const month = document.getElementById('id_monthDate').value
    const year = document.getElementById('id_yearDate').value
    // console.log((day != 'JJ' && month != 'MM' && year != 'AAAA'))
    if (day != 'JJ' && month != 'MM' && year != 'AAAA') {
        const value = year + '-' + addZero(month, 2) + '-' + addZero(day, 2)
        const previewValue = addZero(day, 2) + '/' + addZero(month, 2) + '/' + year
        // console.log(value)
        this.getDate(value, previewValue)

        document.getElementById('id_validateDate').disabled = true;
    }
  };

  optinAgeInfo = (age) => {
    console.log(age)
    // const options = [
    //   { text: "Oui super !", handler:  () => this.getOptinAge('Oui super', 'oui'), id: 'oui' },
    //   { text: "Non merci", handler:  () => this.getOptinAge('Non merci', 'non'), id: 'non' },
    // ];
  
    // const optionsMarkup = options.map((option) => (
    //   <button
    //       id={`option-${option.id}`}
    //       className="btn btn-rounded btn-secondary btn-sm btnOptinEmail"
    //       key={option.id}
    //       onClick={option.handler}
    //     >
    //     {option.text}
    //   </button>
    // ));
  
    return  (<div className="learning-options-container">
        {/* {
          age < 25 ? 
          <span>Nous t'offrons l'abonnement au pack numérique Ouest-France, comme cela tu liras toute l'actualité des festivals gratuitement !🎉🔥</span>
          : 
          <span>Nous t'offrons 2 mois au pack numérique Ouest-France, comme cela tu liras toute l'actualité des festivals gratuitement !🎉🔥</span>
        } */}
        <span>C'est noté !<br/> <b>Tu vas recevoir un email pour obtenir ton cadeau !</b> ✌🔥</span>
        </div>);
  }

  handleChangeReglement = ({target}) => {
    if (target.checked){
      document.getElementById('validateRegelement').disabled = false;
      document.getElementById('validateRegelement').removeAttribute('disabled');
      document.getElementById('validateRegelement').classList.remove('disableCss');
      document.getElementById('id_cheboxReglement').classList.add('selected');
      
   } else {
      document.getElementById('validateRegelement').disabled = true;
      document.getElementById('validateRegelement').setAttribute('disabled', 'disabled');
      document.getElementById('validateRegelement').classList.add('disableCss');
      document.getElementById('id_cheboxReglement').classList.remove('selected');

   }

  }

  handleChange = ({target}) => {
    if (target.checked){
      document.getElementById('validate').disabled = false;
      document.getElementById('validate').removeAttribute('disabled');
      document.getElementById('validate').classList.remove('disableCss');
      document.getElementById('id_cheboxNewsLetter').classList.add('selected');
      
   } else {
      document.getElementById('validate').disabled = true;
      document.getElementById('validate').setAttribute('disabled', 'disabled');
      document.getElementById('validate').classList.add('disableCss');
      document.getElementById('id_cheboxNewsLetter').classList.remove('selected');

   }
    // document.getElementById('validate').addEventListener('click', function() { 
    //   this.getNewsLetter('Je m\'inscris', 'oui');
    // });

  }

  optinNewLetterMessage = () => {
  
    return (
      <div className="learning-options-container">
          <span>Pour rester informé(e) tout l’été de l’actu des festivals dans l’ouest (comptes rendus, photos...) <strong>inscris-toi à la Newsletter hebdomadaire "Festivals"</strong> 🎉</span>
          <div className='blocfestivalYes'>
            <img src={FestivalYes} className="festivalYes" />
          </div>
          <div className='contanierCheck'>
              <label htmlFor="oui_newsLetter" id="id_cheboxNewsLetter">
                <input type="checkbox" 
                  className="oui_newsLetter" 
                  id="oui_newsLetter" 
                  onChange= {(e) => {
                    this.handleChange(e);
                  }}
                  /> Oui, je m’inscris ! 🚀
              </label>

              <button id='validate' 
                  className='btn btn-rounded btn-secondary btn-sm disableCss'
                  onClick = {(e) => {
                      if (!e.target.classList.contains('disableCss')) {
                        console.log('sans disableCss');
                        this.getNewsLetter('Oui, je m’inscris ! 🚀', 'oui');
                      } else {
                        console.log('avec disableCss');
                      }
                  }}
                  >
                  <img className='iconSend' src={SendIcon} />
              </button>
              <div className='ignoreAction'>
                  <button 
                    id='idignoreBtn'
                    className='ignoreBtn'
                    onClick={() => {
                      this.getNewsLetter('Ignorer', 'non')
                    }}
                    >Ignorer</button>
              </div>
          </div>

          {/* {optionsMarkup} */}
      </div>
      );
  }

  optinRegelementMessage = () => {
  
    return (
      <div className="learning-options-container">
          <span>Afin de finaliser ta participation, merci de <b>valider le règlement !</b> 👍</span>
          <a href='#' className='linkReglement' 
            onClick= {(e) => {
              this.showRelegment(e);
            }}>Voir le règlement</a>
          <div className='contanierCheck'>
              <label htmlFor="oui_reglement" id="id_cheboxReglement">
                <input type="checkbox" 
                  className="oui_reglement" 
                  id="oui_reglement" 
                  onChange= {(e) => {
                    this.handleChangeReglement(e);
                  }}
                  /> Oui, j’accepte ! 🚀
              </label>

              <button id='validateRegelement' 
                  className='btn btn-rounded btn-secondary btn-sm disableCss'
                  onClick = {(e) => {
                      if (!e.target.classList.contains('disableCss')) {
                        this.getReglement('Oui, j\’accepte ! 🚀', 'oui');
                      }
                  }}
                  >
                  <img className='iconSend' src={SendIcon} />
              </button>
          </div>

          {/* {optionsMarkup} */}
      </div>
      );
  }


  showRelegment = (e) => {
    document.getElementById('id_pageReglement').classList.remove('hide');

    const url = new URL(window.location);
    url.searchParams.set('regelement', '1');
    window.history.pushState({}, '', url);

    return false;
  }


  getGender = (title, value) => {
    //console.log(title)
    const greetingMessage = this.createClientMessage(title);
    this.updateChatbotState(greetingMessage);

    const champ = getCoordonnees('civilite');
    saveResponse(champ.id, value, champ.type);

    document.getElementById('option-homme').disabled = true;
    document.getElementById('option-femme').disabled = true;
    

    //this.collecteData('optin');
    //console.log("civilité" + " : " +parseInt(champ.ordre))
    //if ((parseInt(champ.ordre) + 1) < Object.values(getChamps()).length) {
    if (!champ.is_last) {
      this.collecteData(parseInt(champ.ordre) + 1);
    } else {
      this.remerciement();
    }
  }

  getNewsLetter = (title, value) => {
    //console.log(title)
    const greetingMessage = this.createClientMessage(title);
    this.updateChatbotState(greetingMessage);

    const champ = getCoordonnees('optin_newsletter');
    saveResponse(champ.id, value, champ.type);

    document.getElementById('validate').disabled = true;
    document.getElementById('idignoreBtn').disabled = true;
    document.getElementById('oui_newsLetter').disabled = true;

  
    if (!champ.is_last) {
      this.collecteData(parseInt(champ.ordre) + 1);
    } else {
      this.remerciement();
    }
  }

  getReglement = (title, value) => {
    //console.log(title)
    const greetingMessage = this.createClientMessage(title);
    this.updateChatbotState(greetingMessage);

    const champ = getCoordonnees('optin_reglement');
    saveResponse(champ.id, value, champ.type);

    document.getElementById('validateRegelement').disabled = true;
    document.getElementById('oui_reglement').disabled = true;

  
    if (!champ.is_last) {
      this.collecteData(parseInt(champ.ordre) + 1);
    } else {
      this.remerciement();
    }
  }

  getOptin = (title, value) => {
    // console.log(value)
    const greetingMessage = this.createClientMessage(title)
    this.updateChatbotState(greetingMessage);

    const champ = getCoordonnees('optin');
    saveResponse(champ.id, value, champ.type);

    document.getElementById('option-oui').disabled = true;
    document.getElementById('option-non').disabled = true;

    if (value === 'oui') {
      document.getElementById('option-oui').classList.add('selected');
    } else {
      document.getElementById('option-non').classList.add('selected');
    }
    
    //console.log("OPtin" + " : " +parseInt(champ.ordre))
    //if ((parseInt(champ.ordre) + 1) < Object.values(getChamps()).length) {
    if (!champ.is_last) {
      this.collecteData(parseInt(champ.ordre) + 1);
    } else {
      // alert('remerciement ');
      this.remerciement();
      // alert(localStorage.getItem('fusioned_img'));
    }
  }

  getDate = (value, previewValue) => {
    const ageUser = getAge(value)
    console.log(ageUser)
    const greetingMessage = this.createClientMessage(previewValue)
    this.updateChatbotState(greetingMessage);

    const champ = getCoordonnees('date');
    saveResponse(champ.id, value, champ.type);

    let message = this.optinAgeInfo(ageUser);
    var options = {
        key : '',
        champ_id : '',
        ordre : '',
        is_last : false,
        required : false,
        delay : 3,
        withAvatar: true,
    };
    
    var botMessage = this.createChatBotMessage(message, options);    
    this.updateChatbotState(botMessage);
    
    if (!champ.is_last) {      
      setTimeout(() => {
        this.collecteData(parseInt(champ.ordre) + 1);
      }, 2000)
    } else {
      this.remerciement();
    }
  }

  getOptinAge = (title) => {
    const greetingMessage = this.createClientMessage(title)
    this.updateChatbotState(greetingMessage);

    const champ = getCoordonnees('date');
    if (!champ.is_last) {
      this.collecteData(parseInt(champ.ordre) + 1);
    } else {
      this.remerciement();
    }

  }

  messageRemerciement = () => {
    var dataChamps = window.dataChamps;
    // console.log(dataChamps);
    var champOptinImpression = parseInt(dataChamps[2].value);

    return (
      <>
        <span>🙏 Merci ta participation est bien prise en compte !
        <br/>
        {champOptinImpression ? (<b>On te donne rdv sur notre stand pour récupérer ta photo imprimée ! 👋</b>) : (<b>Nous t'envoyons immédiatement ta photo par email ! 👋</b>)}
        </span>
        <div className='blocGoodLuckImg'>
          <img src={EzGif} className="GoodLuckImg"/>
        </div>
      </>
    )
  }

  messageTelechargement = () => {
    return (
      <>
        <span>N'hésite pas à <b>partager ta photo</b> sur tes réseaux sociaux favoris ! ❤️ 🔥‍</span>
        <button 
          className='btn btn-telecharment btn-sm btn-rounded'
          onClick = {(e) => {
            donwloadImage();
          }}
          >
          <img className='iconDownload' src={DownloadIcon} /> Télécharger
        </button>
      </>
    )
  }

  messageFinal = () => {
    return (
      <>
        <span>Bon festival 🎉 et <b>retrouve-nous sur le stand Ouest-France sur le festival</b> où l'on a pas fini de t'étonner ! 👍</span>
        <a 
          className='btn btn-retour btn-sm btn-rounded'
          href='/'
          >
          <img className='iconBack' src={HomeIcon} /> Retour à l’accueil
        </a>
      </>
    )
  }

  messageEnvoiData = () => {
    console.log('modal')
    //document.querySelector("#id_modalEnvoi").parentElement.classList.add('show');
    /*return (
      <>
      <Modal
      //size="lg"
      //show={lgShow}
      //onHide={() => setLgShow(false)}          
      show="true"
      //onHide={() => {}}

      aria-labelledby="example-modal-sizes-title-lg"
      >
      <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Transfert manuel
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={() => {}} >Fermer</Button>
          <Button variant="primary" onClick={() => {}} >Ok</Button>
      </Modal.Footer>
      </Modal>
  </>
    )*/
  }

  messageLoaderPhoto = () => {
     return (
      <div className="loaderMessagePhoto" id="lodaerMessagebloc">
        <div id="containerLoader" className="loadingio-spinner-rolling-tg9xsw2f5t">
          <div className="ldio-z4j07sq3ty">
            <div></div>
          </div>
        </div>
        <img src={Done} className='chekImage hide' id="chekImageLoader" />
        <div id="textLoaderEvnoi" className='textLoader'>⚠️  <b>L’envoi des informations est en cours, merci de patienter quelques instants ! 💪</b></div>
      </div>
    )
  }

  remerciement = () => {
    var optionsRemerciement = {
      delay : 0,
      withAvatar : true,
    };
    if (enCoursEnvoi >0) {
      console.log(' Ajax en cours envoi photo');
      const botMessageLoaderPhoto = this.createChatBotMessage(this.messageLoaderPhoto());
      this.updateChatbotState(botMessageLoaderPhoto);
      optionsRemerciement.delay = 2000;
    } else {
      console.log('photo envoyé');
    }

    uploadData();

    const botMessage = this.createChatBotMessage(this.messageRemerciement(), optionsRemerciement);
    this.updateChatbotState(botMessage);
    //console.log(JSON.parse(localStorage.getItem("user_responses")));
    
    this.disableForm();
    const inputBloc = document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    inputBloc.classList.add('hide');
    document.querySelector(".react-chatbot-kit-chat-message-container").style = `height: ${document.documentElement.clientHeight}px`;
    // window.scrollTo(0, document.querySelector(".react-chatbot-kit-chat-message-container").scrollHeight);

    const botMessageTelechargement = this.createChatBotMessage(this.messageTelechargement(), {
      delay: 2000,
      withAvatar: true,
    });
    this.updateChatbotState(botMessageTelechargement);

    const botMessageFinal = this.createChatBotMessage(this.messageFinal(), {
      delay: 3000,
      withAvatar: true,
    });
    this.updateChatbotState(botMessageFinal);

    //setTimeout(uploadData(), 5000);
  }

  generateFile = () => { 
    /*var filePath = 'filename.json';
    var fileSysObj = new ActiveXObject('Scripting.FileSystemObject');

    fileSysObj.CreateTextFile(filePath);*/
  }

  optinEmail = () => {
    const options = [
      { text: "Homme" , id: 'homme' },
      { text: "Femme", id: 'femme' },
    ];
  
    const optionsMarkup = options.map((option) => (
      <button
        id={`option-${option.id}`}
        //className="learning-option-button"
        className="btn btn-rounded btn-secondary btn-sm"
        key={option.id}
        // onClick={option.handler}
      >
        {option.text}
      </button>
    ));
  
    return  (<div className="learning-options-container">
        {optionsMarkup}
        </div>);
  };

}

export default ActionProvider;