import React, { useState } from 'react';

import "../styles/options.css";

const DateInput = (props) => {
  //console.log(props)
  // const { onInputChange } = props
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    const value = event.target.value;
    console.log(value)
    // onInputChange(value);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   props.triggerNextStep({ value: selectedDate, trigger: props.nextStep });
  // };

  return (
    <div>
      {/* <form onSubmit={handleSubmit}> */}
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
        {/* <button type="submit">Envoyer</button> */}
      {/* </form> */}
    </div>
  );
};

export default DateInput;