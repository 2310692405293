import React from "react";

import "../styles/options.css";

const OptionsNewLetter = (props) => {
  const options = [
    { text: "Je m’inscris c’est parti ! 🚀", handler:  () => props.actionProvider.getNewsLetter('Je m\'inscris', 'oui'), id: 'is_newsletter' },
    { text: "Passer", handler:  () => props.actionProvider.getNewsLetter('Passer', 'non'), id: 'not_newsletter' },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      id={`option-${option.id}`}
      //className="learning-option-button"
      className="btn btn-rounded btn-secondary btn-sm kl_optin_newsletter"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default OptionsNewLetter;