
import { useNavigate } from 'react-router-dom'

export default function SelectedPhotos() {
    const navigate = useNavigate();
    return (
        <div>Photo générée

            <button onClick={() => navigate('/chatbot')} >Go to Chatbot</button>
        </div>
    )
}
