import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useStyle } from "./Home.style";
import Image from "../../assets/img";
import classnames from "classnames";
import Logotop from '../../assets/img/logo-festival-beaugregard.svg';
import ReactGA from "react-ga4";


export default function Home() {
  const navigate = useNavigate();
  const classes = useStyle();

  // setTimeout(() => {
  //   if (!window.matchMedia || !window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //     var boxContainerElem = document.getElementsByClassName(classes.boxContainer);
  //     // if (boxContainerElem.length) boxContainerElem[0].classList.add('bg-white-dark');
  //     if (boxContainerElem.length) {
  //       // boxContainerElem.item(0).classList.add('bg-white-dark');
  //       // boxContainerElem.item(0).setAttribute('style', 'background-color:#5db77a !important;');
  //       // boxContainerElem.item(0).setAttribute('style', 'background-image: url("./bg-blanc.jpg") !important');
  //       // boxContainerElem.item(0).setAttribute('style', 'background: linear-gradient(to bottom, #fff, #5bb77a);');
  //     }
  //   }
  // }, 1500)

  // setTimeout(() => {
  //   var boxContainerElem = document.getElementsByClassName(classes.boxContainer);    
  //   // idDebugHome
  //   // if (boxContainerElem.length) document.getElementById('idDebugHome').innerHTML = !window.matchMedia + ' / ' + !window.matchMedia('(prefers-color-scheme: dark)').matches
  //   // if (boxContainerElem.length) document.getElementById('idDebugHome2').innerHTML = (boxContainerElem[0].classList);
  //   // if (boxContainerElem.length) document.getElementById('idDebugHome3').innerHTML = (boxContainerElem.item(0).getAttribute('style'));
  // }, 3000)

  const goToPhoto = () => {
    ReactGA.event({
      category: 'Home',
      action: 'C\'est parti'
    });
    navigate("/photo");
  }

  return (
    <>
      <Container className="App HomeApp" maxWidth="sm" style={{ position: "relative" }}>
        <Grid className={classes.gridTopImage}>
          <img src={Logotop} className="logoTopHome" />
        </Grid>
        <Box className={classes.boxContainer}>
          <Grid className={classes.gridMiddleImage}>
            <img src={Image.hpMiddleImg} />
          </Grid>
          <Grid>
            <Typography className={classnames(classes.typography)}>
              Reçois ta photo personnalisée et viens la chercher <b>sur le stand Ouest-France !</b>
            </Typography>
          </Grid>
        </Box>
        <Grid className={classes.gridButton}>
          <Button
            onClick={() => goToPhoto()}
            variant="contained"
            size="large"
            startIcon={<RocketLaunchIcon style={{ fontSize: "35px" }} />}
            className={classes.goButton}
          >
            C'est parti
          </Button>
          <div id="idDebugHome" className="dbg_mobile"></div>
          <div id="idDebugHome2" className="dbg_mobile"></div>
          <div id="idDebugHome3" className="dbg_mobile"></div>
        </Grid>
        <Grid className={classes.gridLastImage}>
          <Typography className={classes.typographyLastImage}>AVEC</Typography>
          <img src={Image.ouestFranceImgBlanc} style={{ width: "120px" }} />
        </Grid>
      </Container>
      <Container className="footterApp" maxWidth="sm" style={{ position: "relative" }}>
        <Grid className={classes.menuBottom}>
            <Typography className={classes.menuTypoBottom} onClick={() => navigate("/condition-jeu")}>Voir les conditions du jeu</Typography>
            <Typography className={classes.menuTypoBottom} onClick={() => navigate("/mention-legale")}>Mentions légales</Typography>
        </Grid>
      </Container>
    </>
  );
}
