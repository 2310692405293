import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  header: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    borderRadius: "50% !important",
    minWidth: "35px !important",
    height: "35px !important",
    border: "3px solid #fff !important",
    padding: "0 !important"
  },
  containerTypo: {
    textAlign: "justify",
  },
  headerTypo: {
    fontSize: "1.5rem !important",
    margin: "15px 0 !important",
    fontWeight: "bold !important",
    textAlign: "center !important",
    width: "100%"
  },
}));
