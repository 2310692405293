import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import "./index.css";
import "./responsive.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));

window.champs = [];
window.dataChamps = [];
window.dataPhoto = {};

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
